// @ts-nocheck

import { flatten } from 'lodash';

export function plural(count: number, ...words: string[]) {
  const cases = [2, 0, 1, 1, 1, 2],
    titles = flatten(words);
  return titles[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]];
}

//TODO: типировать
export const shiftIndent = (str) => {
  const lines = str.split('\n').filter((line) => line.trim()),
    extraIndent = indentCount(lines[0]);
  return lines.map((line) => line.slice(extraIndent)).join('\n');
};

export const indentCount = (str) => {
  const symbols = str.split('');
  let count = 0;
  for (let i = 0; i < symbols.length; i++) {
    if (symbols[i] !== ' ') return count;
    count++;
  }
};

export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy');
    } catch (ex) {
      return prompt('Copy to clipboard: Ctrl+C, Enter', text);
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

export function matchRate(content, search) {
  content = content.toLowerCase().trim();
  search = search.toLowerCase().trim();
  if (content.includes(search)) return 1;
  return similarity(content, search);
}

export function similarity(s1, s2) {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

export function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export function formatPrice(v) {
  v = parseInt(v + '') + '';
  let r = '';
  while (v) {
    r = v.slice(-3) + ' ' + r;
    v = v.slice(0, -3);
  }
  return r;
}
