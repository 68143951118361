import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { clsx } from 'clsx';
import { useMediaQuery } from '@mui/material';

import { icons } from '@icons/icons';

import { Icon } from '../Icon/Icon';

import styles from './Modal.module.scss';

interface ModalProps {
  onClose?: () => void;
  children: ReactNode;
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
  closeIconClassName?: string;
  backdropClassName?: string;
  isRedesign?: boolean;
  variant?: 'default' | 'new';
  iconPosition?: 'left' | 'right';
  customCloseIcon?: ReactNode | boolean;
}

export const Modal: FC<ModalProps> = ({
  onClose,
  children,
  className,
  containerClassName,
  contentClassName,
  closeIconClassName,
  backdropClassName,
  isRedesign,
  variant,
  iconPosition,
  customCloseIcon = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  const containerClasses = clsx(styles.container, containerClassName);
  const backdropClasses = clsx(styles.backdrop, backdropClassName);
  const contentClasses = clsx(styles.content, contentClassName, {
    [styles.content_redesign]: isRedesign,
  });
  const closeIconClasses = clsx(styles.close, closeIconClassName, {
    [styles.close_pos]: iconPosition,
  });

  const overlayRootElement = document.getElementById('root-overlay') as Element;

  return overlayRootElement
    ? createPortal(
        <div className={`${styles.modal} ${className}`}>
          <div className={backdropClasses}></div>
          <div className={containerClasses}>
            <div className={contentClasses} data-variant={variant}>
              {customCloseIcon ? (
                customCloseIcon
              ) : (
                <div className={closeIconClasses} onClick={() => onClose && onClose()}>
                  <Icon name={isMobile ? icons.closeMobile : icons.close} />
                </div>
              )}
              <section>{children}</section>
            </div>
          </div>
        </div>,
        overlayRootElement,
      )
    : null;
};
