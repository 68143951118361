import { GradientBlockProgress } from '@shared/GradientBlockProgress/GradientBlockProgress';
import styles from './PaymentLayout.module.scss';
import { useEffect } from 'react';

type PaymentLayoutProps = {
  isLoading: boolean;
  communityTitle?: string;
  children: React.ReactNode;
  className?: string;
};

export const PaymentLayout = ({
  children,
  isLoading = false,
  communityTitle,
  className,
}: PaymentLayoutProps) => {
  useEffect(() => {
    if (communityTitle) {
      window.document.title = `Оплата подписки на сообщество ${communityTitle}`;
    }
    window.document.body.classList.add(styles.back);
  }, [communityTitle]);

  return (
    <div className={styles.layout + ' ' + className}>
      <div className={styles.logo}>
        <a href='/' target='_blank'>
          <img src='/images/logo/logo.svg' alt='logo' />
        </a>
      </div>
      {children}
      <div className={styles.copyright}>
        <div className={styles.copyrightText}>© 2024, ООО «Андромеда». Все права защищены.</div>
      </div>
      <GradientBlockProgress show={isLoading} />
    </div>
  );
};
