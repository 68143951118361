// @ts-nocheck

// TODO: типизировать

export const formatPhoneByMask = (profilePhone) => {
  const plusCharacter = profilePhone.slice(0, 1);
  const countryCode = profilePhone.slice(1, 2);
  const operatorCode = profilePhone.slice(2, 5);
  const phoneCodeOne = profilePhone.slice(5, 8);
  const phoneCodeTwo = profilePhone.slice(8, 10);
  const phoneCodeThree = profilePhone.slice(10, 12);

  return `${plusCharacter} ${countryCode} ${operatorCode} ${phoneCodeOne}-${phoneCodeTwo}-${phoneCodeThree}`;
};
