import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import AppRoutes from './AppRoutes';
import GlobalLoader from '@components/common/GlobalLoader';
import { FinishImpersonateButton } from '@components/main/auth/FinishImpersonateButton/FinishImpersonateButton';
import ErrorBoundary from '@widgets/error/ErrorBoundary';

import { AppDispatch, RootState } from '@store/index';

import styles from './App.module.scss';
import { useOverrideTheme } from '@hooks/useOverrideTheme';
import { initMessenger } from '@store/messenger/messenger';

const App = () => {
  const theme = useOverrideTheme();
  // usePreventPWAInstall();
  const isGlobalLoading = useSelector((state: RootState) => state.common.isGlobalLoading);
  const user = useSelector((state: RootState) => state.auth.user);
  const isImpersonate = useSelector((state: RootState) => state.auth.isImpersonate);
  const isLanding = window.location.pathname === '/';
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(initMessenger());
    }
  }, [user]);

  // <AddToHomeScreenButton />

  return (
    <ThemeProvider theme={theme}>
      <div className={`${styles.app}${isLanding ? ` ${styles.app_landing}` : ''}`} id='app-root'>
        <ErrorBoundary>
          <AppRoutes />
          {isGlobalLoading && <GlobalLoader />}
          {user && isImpersonate && <FinishImpersonateButton />}
        </ErrorBoundary>
      </div>
    </ThemeProvider>
  );
};

export default App;
