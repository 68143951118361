import { ReactNode, MouseEvent } from 'react';
import { useMediaQuery } from '@mui/material';
import { clsx } from 'clsx';

import { Icon } from '../Icon/Icon';

import styles from './Button.module.scss';

interface ButtonProps {
  children?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  outline?: boolean;
  disabled?: boolean | null;
  icon?: string;
  iconClassName?: string;
  iconName?: string;
  block?: boolean;
  blockMobile?: boolean;
  onClick?: () => void;
  onMouseOver?: (ev: MouseEvent) => void;
  onMouseOut?: (ev: MouseEvent) => void;
  onMouseEnter?: (ev: MouseEvent) => void;
  onMouseLeave?: (ev: MouseEvent) => void;
  onMouseMove?: (ev: MouseEvent) => void;
  height?: string;
  width?: string;
  className?: string;
  contentClassName?: string;
  onDisabledClick?: () => void;
  fullwidth?: boolean;
}

export const Button = ({
  children,
  type,
  size,
  outline,
  disabled,
  icon,
  iconClassName,
  block,
  blockMobile,
  onClick,
  onMouseOver,
  onMouseOut,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  height,
  width,
  className,
  contentClassName,
  onDisabledClick,
  fullwidth,
}: ButtonProps) => {
  const buttonClicked = (ev: MouseEvent) => {
    if (disabled) {
      if (onDisabledClick) {
        onDisabledClick();
      }
      ev.preventDefault();
      return;
    }
    onClick && onClick();
  };
  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <button
      type={type}
      className={`${styles.button} ${className}`}
      data-outline={outline ? 'outline' : ''}
      data-disabled={disabled ? 'disabled' : ''}
      data-size={size}
      data-height={height}
      data-width={width}
      data-fullwidth={fullwidth}
      onClick={buttonClicked}
      data-block={block || (blockMobile && isMobile) ? 'block' : ''}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      {icon && (
        <div className={clsx(styles.icon, { [String(iconClassName)]: iconClassName })}>
          <Icon name={icon} />
        </div>
      )}
      <div className={clsx(styles.content, contentClassName)}>{children}</div>
    </button>
  );
};
