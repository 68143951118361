// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

import axios from '@utils/axios';
import authSlice from '@store/auth/auth';

import { AppDispatch } from '@store/index';
import { ProfileState, VerificationModalPos } from './types';

// TODO: типизировать

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    jobFields: [],
    profile: null,
    requests: null,
    waitingFeedbackQueue: null,
    updatedRequestsCount: 0,
    initialCommunities: null,
    initialProfile: null,
    isShowVerifiedModal: false,
    oldProfilePhone: '',
    verificationModalPos: { right: 0, top: 0 },
    verificationStep: 1,
  } as ProfileState,
  reducers: {
    setJobFields: (state, { payload }) => {
      state.jobFields = payload;
    },
    setProfile: (state, { payload }) => {
      state.profile = payload;
    },
    setRequests: (state, { payload }) => {
      state.requests = payload;
    },
    setFeedbackQueue: (state, { payload }) => {
      state.profile.feedbackQueue = payload;
    },
    setFeedbackSent: (state, { payload }) => {
      state.profile.feedbackSent = payload;
    },
    setWaitingFeedbackQueue: (state, { payload }) => {
      state.waitingFeedbackQueue = payload;
    },
    setUpdatedRequestsCount: (state, { payload }) => {
      state.updatedRequestsCount = payload;
    },
    setInitialCommunities: (state, { payload }) => {
      state.initialCommunities = payload;
    },
    setInitialProfile: (state, { payload }) => {
      state.initialProfile = payload;
    },
    setIsShowVerifiedModal: (state, { payload }) => {
      state.isShowVerifiedModal = payload;
    },
    setVerificationModalPost: (state, { payload }: { payload: VerificationModalPos }) => {
      state.verificationModalPos = payload;
    },
    setVerificationStep: (state, { payload }: { payload: number }) => {
      state.verificationStep = payload;
    },
  },
});

export const resetRequestsUpdated = () => async (dispatch: AppDispatch) => {
  await axios.get('/profile/reset-requests-updated');
  dispatch(fetchUpdatedRequestsCount());
};

export const fetchUpdatedRequestsCount = () => async (dispatch: AppDispatch) => {
  const response = await axios.get('/profile/updated-requests-count');
  dispatch(profileSlice.actions.setUpdatedRequestsCount(response.data));
};

export const fetchFeedbackQueue = () => async (dispatch: AppDispatch) => {
  const response = await axios.get(`/feedback/queue`);
  dispatch(profileSlice.actions.setWaitingFeedbackQueue(null));
  dispatch(profileSlice.actions.setFeedbackQueue(response.data.feedbackQueue));
  dispatch(profileSlice.actions.setFeedbackSent(response.data.feedbackSent));
};

export const addFeedbackToQueue = (serviceName, update) => async (dispatch: AppDispatch) => {
  const response = await axios.post(`/feedback`, serviceName);
  if (update) {
    dispatch(profileSlice.actions.setFeedbackQueue(response.data.feedbackQueue));
    dispatch(profileSlice.actions.setFeedbackSent(response.data.feedbackSent));
  } else {
    dispatch(profileSlice.actions.setWaitingFeedbackQueue(serviceName));
  }
};

export const removeFeedbackFromQueue = (serviceName) => async (dispatch: AppDispatch) => {
  const response = await axios.delete(`/feedback/queue`, {
    params: {
      serviceName,
    },
  });
  dispatch(profileSlice.actions.setFeedbackQueue(response.data.feedbackQueue));
  dispatch(profileSlice.actions.setFeedbackSent(response.data.feedbackSent));
};

export const sendFeedback = (data) => async () => {
  await axios.post(`/feedback`, data);
};

export const fetchRequests = () => async (dispatch: AppDispatch) => {
  const response = await axios.get('/profile/requests');
  dispatch(profileSlice.actions.setRequests(response.data));
};

export const uploadImage = (blob) => async (dispatch: AppDispatch) => {
  const formData = new FormData();
  formData.append('picture', blob, 'avatar.jpg');
  const response = await axios.post('/profile/upload-picture', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const picture = response.data;
  dispatch(authSlice.actions.setPicture(picture));
};

export const removeAvatar = () => async (dispatch: AppDispatch) => {
  const response = await axios.delete('/profile/remove-picture');
  dispatch(authSlice.actions.setUser(response.data));
};

export const fetchProfile = () => async (dispatch: AppDispatch) => {
  const { data } = await axios.get('/profile/current');

  if (data && data.profile) {
    dispatch(profileSlice.actions.setProfile(data.profile));
    dispatch(profileSlice.actions.setInitialCommunities(data.profile.communities));
    dispatch(profileSlice.actions.setInitialProfile(data.profile));
  }
};

export const loadJobFields = (params) => async (dispatch: AppDispatch) => {
  const response = await axios.get('/profile/job-fields/', { params });
  dispatch(profileSlice.actions.setJobFields(response.data));
};

export default profileSlice;
