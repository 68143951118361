// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';
import { LandingState } from './types';

// TODO: типизировать

const landingSlice = createSlice({
  name: 'landing',
  initialState: {
    previousPage: '',
    isShowLeadModal: false,
    isShowPriceModal: false,
    isShowSuccessModal: false,
    routePath: '',
    isClickByPrice: false,
    currentSliderRef: '',
  } as LandingState,
  reducers: {
    setPreviousPage: (state, { payload }: { payload: string }) => {
      state.previousPage = payload;
    },
    setIsShowLeadModal: (state, { payload }: { payload: boolean }) => {
      state.isShowLeadModal = payload;
    },
    setIsShowPriceModal: (state, { payload } : { payload: boolean }) => {
      state.isShowPriceModal = payload;
    },
    setIsShowSuccessModal: (state, { payload }: { payload: boolean }) => {
      state.isShowSuccessModal = payload;
    },
    setRoutePath: (state, { payload }: { payload: string }) => {
      state.routePath = payload;
    },
    setIsClickByPrice: (state, { payload }: { payload: boolean }) => {
      state.isClickByPrice = payload;
    },
    setCurrentSliderRef: (state, { payload }: { payload: string }) => {
      state.currentSliderRef = payload;
    },
  },
});

export const requestLead = (data) => async (dispatch: AppDispatch) => {
  const correctLeadData = {
    community: !data.community ? null : data.community,
    description: !data.description ? null : data.description,
    name: !data.name ? null : data.name,
    phone: !data.phone ? null : `+${data.phone}`,
    position: !data.position ? null : data.position,
    email: !data.email ? null : data.email,
    residentsCount: !data.residentsCount ? null : Number(data.residentsCount),
  };

  await axios.post('/demo/requests', correctLeadData);

  dispatch(landingSlice.actions.setIsShowSuccessModal(true));
};

export default landingSlice;
