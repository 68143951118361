import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';
import { CompetenceState, Skill, ProfileSkills } from './types';

export const competenceSlice = createSlice({
  name: 'competence',
  initialState: {
    skills: [],
  } as CompetenceState,
  reducers: {
    setSkills: (state, { payload }: { payload: Skill[] }) => {
      state.skills = payload;
    },
  },
});

export const loadSkills = () => async (dispatch: AppDispatch) => {
  const response = await axios.get('/profile/skills/');

  if (response.status === 200) {
    dispatch(competenceSlice.actions.setSkills(response.data));
  }
};

export const updateSkills = (data: ProfileSkills) => async () => {
  await axios.put('/profile/update-skills/', data);
};

export default competenceSlice;
