import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '@utils/axios';
import styles from './PaySubscription.module.scss';
import { OrderType } from '@features/payments/types/order.type';

import { SubscriptionType } from '@features/payments/types/subscription.type';
import { PAYMENT_METHODS } from '@features/payments/constants/payment-methods.const';
import { PaymentLayout } from '@features/payments/layouts/PaymentLayout/PaymentLayout';
import { SubscriptionDescription } from '@features/payments/components/SubscriptionDescription/SubscriptionDescription';
import { PaymentButton } from '@features/payments/components/PaymentButton/PaymentButton';

export const PaySubscription = () => {
  const { paymentHash } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderType | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState<SubscriptionType | null>(null);
  const [phone, setPhone] = useState<string | null>(null);

  const proceedPayment = useCallback(async () => {
    if (!selectedMethod) return;
    setIsLoading(true);
    const { data: paymentUrl } = await axios.post(`/payments/proceed`, {
      paymentMethod: selectedMethod,
      orderId: order?.id,
    });
    if (!paymentUrl) {
      return navigate(`/lead/services`);
    }
    window.open(paymentUrl, '_top');
  }, [selectedMethod, order?.id, navigate]);

  const init = useCallback(async () => {
    try {
      const {
        data: { order, subscription, phone },
      } = await axios.post(`/payments/current-order`, {
        paymentHash,
      });
      setOrder(order);
      setSubscription(subscription);
      setPhone(phone);
    } catch (error) {
      return navigate(`/lead/services`);
    }
  }, [paymentHash, navigate]);

  useEffect(() => {
    init();
  }, [init]);

  const paymentLabel = selectedMethod
    ? `Оплатить ${PAYMENT_METHODS.find((method) => method.name === selectedMethod)?.label}`
    : 'Перейти к оплате';

  if (!order || !subscription) return null;

  return (
    <PaymentLayout
      isLoading={isLoading}
      communityTitle={subscription.community.title}
      className={styles.payCommunity}
    >
      <SubscriptionDescription subscription={subscription} order={order} phone={phone!} />
      <div className={styles.splitter}></div>
      <div className={styles.paymentMethods}>
        <div className={styles.label}>Выберите способ оплаты:</div>
        <div className={styles.methods}>
          {PAYMENT_METHODS.map((method) => (
            <div
              className={styles.method}
              key={method.name}
              onClick={() => setSelectedMethod(method.name)}
              data-selected={selectedMethod === method.name}
            >
              <div className={styles.icon}>
                <img src={method.iconImage} alt={method.title} />
              </div>
              <div className={styles.title}>{method.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rules}>
        Нажимая кнопку <strong>«{paymentLabel}»</strong>, вы соглашаетесь
        <br /> с{' '}
        <a href='/docs/Privacy_Policy.pdf' target='_blank'>
          политикой конфиденциальности
        </a>
        и{' '}
        <a href='/docs/Agreement.pdf' target='_blank'>
          пользовательским соглашением
        </a>
      </div>
      <div className={styles.footer}>
        <PaymentButton
          disabled={!selectedMethod}
          onClick={proceedPayment}
          tooltip={selectedMethod ? '' : 'Выберите способ оплаты'}
        >
          {paymentLabel}
        </PaymentButton>
      </div>
    </PaymentLayout>
  );
};
