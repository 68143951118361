// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';
import axiosLib from 'axios';

import { getIdsCompareEditedArrays } from '@utils/compareEditedArray';
import { removeEmptyFieldValues } from '@utils/removeEmptyFieldValues';
import { filterDeepErrorsByField } from '@utils/validate';

import commonSlice from '../common/common';

import { PROJECTS_FIELD_ERRORS } from './constants';

// TODO: типизировать

import { AppDispatch, RootState } from '@store/index';
import { ProfileProjectState, ProjectError } from './types';

const profileProjectsSlice = createSlice({
  name: 'profileProjects',
  initialState: {
    initialProfileProjects: [],
    profileProjects: [],
    isProjectEdit: false,
    initialProject: {
      name: '',
      description: '',
      startYear: '',
      finishYear: '',
      untilNow: false,
      link: '',
      users: [],
      visible: true,
    },
    errors: [],
    projectUsers: [],
  } as ProfileProjectState,
  reducers: {
    setIsProjectEdit: (state, { payload }) => {
      state.isProjectEdit = payload;
    },
    setProfileProjects: (state, { payload }) => {
      state.profileProjects = payload;
    },
    setInitialProfileProjects: (state, { payload }) => {
      state.initialProfileProjects = payload;
    },
    setErrors: (state, { payload }: { payload: ProjectError }) => {
      const findingError = state.errors.find((error) => error.id == payload.id);

      state.errors = findingError
        ? state.errors.map((error) => {
            if (error.id === payload.id) {
              return {
                ...error,
                projectName:
                  payload.projectName === '' ? '' : payload.projectName || error.projectName,
                webSite: payload.webSite === '' ? '' : payload.webSite || error.webSite,
                description:
                  payload.description === '' ? '' : payload.description || error.description,
                period: payload.period === '' ? '' : payload.period || error.period,
              };
            }
            return error;
          })
        : [...state.errors, payload];
    },
    resetProjects: (state) => {
      state.errors = [];
      state.isProjectEdit = false;
    },
  },
});

export const saveProjects =
  (userId: number, projects) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const newProjects = projects.filter((project) => !project.id);

    if (newProjects.length > 0) {
      dispatch(saveProfileProjects(userId, removeEmptyFieldValues(newProjects, true)));
    }

    const updatedProjects = projects.filter((project) => project.id);
    const filteredProfileProjects = getState()
      .profileProjects.initialProfileProjects.filter((project) => project.id)
      .map((projects) => {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { profileId, ...rest } = projects;
        return { ...rest };
      });

    if (getIdsCompareEditedArrays(updatedProjects, filteredProfileProjects).length > 0) {
      const projectsForUpdate = updatedProjects
        .filter((obj) =>
          getIdsCompareEditedArrays(updatedProjects, filteredProfileProjects).includes(obj.id),
        )
        .map((project) => {
          if (project.startYear === null && project.finishYear === null) {
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            const { startYear, finishYear, ...rest } = project;
            return rest;
          } else {
            return project;
          }
        });

      dispatch(updateProfileProjects(userId, projectsForUpdate));
    } else {
      dispatch(profileProjectsSlice.actions.setIsProjectEdit(false));
    }

    const deletedProjects = getState().profileProjects.initialProfileProjects.filter(
      (initialProject) =>
        !projects.some((currentEducation) => currentEducation.id === initialProject.id),
    );

    if (deletedProjects.length > 0) {
      dispatch(deleteProfileJobs(userId, deletedProjects));
    }
  };

export const fetchProfileProjects = (userId: number) => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.setLoading(true));

  try {
    const response = await axios.get(`/profile/${userId}/projects`);

    if (response.data) {
      const correctData = response.data.map((project) => ({
        ...project,
        startYear: project.startYear || '',
        finishYear: project.finishYear || '',
      }));

      dispatch(profileProjectsSlice.actions.setProfileProjects(correctData));
      dispatch(profileProjectsSlice.actions.setInitialProfileProjects(response.data));
    }
    dispatch(commonSlice.actions.setLoading(false));
  } catch (e) {
    // TODO handle error
    dispatch(commonSlice.actions.setLoading(false));
  }
};

export const saveProfileProjects =
  (userId: number, projectsData) => async (dispatch: AppDispatch) => {
    dispatch(commonSlice.actions.setLoading(true));

    try {
      await axiosLib.all(
        projectsData.map((project) => axios.post(`/profile/${userId}/projects`, project)),
      );
      await dispatch(fetchProfileProjects(userId));

      dispatch(profileProjectsSlice.actions.setIsProjectEdit(false));
      dispatch(commonSlice.actions.setLoading(false));
    } catch (e) {
      if (e?.response.data.statusCode === 400) {
        dispatch(handleValidateErrors(e));
      }

      dispatch(commonSlice.actions.setLoading(false));
    }
  };

export const updateProfileProjects =
  (userId: number, projectsData) => async (dispatch: AppDispatch) => {
    dispatch(commonSlice.actions.setLoading(true));

    try {
      await axiosLib.all(
        projectsData.map((project) => {
          // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
          const { id, profileId, startMobileText, ...rest } = project;
          return axios.patch(`/profile/${userId}/projects/${id}`, rest);
        }),
      );
      await dispatch(fetchProfileProjects(userId));
      dispatch(profileProjectsSlice.actions.setIsProjectEdit(false));
      dispatch(commonSlice.actions.setLoading(false));
    } catch (e) {
      if (e?.response.data.statusCode === 400) {
        dispatch(handleValidateErrors(e));
      }

      dispatch(commonSlice.actions.setLoading(false));
    }
  };

export const deleteProfileJobs =
  (userId: number, projectsData) => async (dispatch: AppDispatch) => {
    dispatch(commonSlice.actions.setLoading(true));

    try {
      await axiosLib.all(
        projectsData.map((project) => axios.delete(`/profile/${userId}/projects/${project.id}`)),
      );
      await dispatch(fetchProfileProjects(userId));

      dispatch(profileProjectsSlice.actions.setIsProjectEdit(false));
      dispatch(commonSlice.actions.setLoading(false));
    } catch (e) {
      // TODO handle error
      dispatch(commonSlice.actions.setLoading(false));
    }
  };

const handleValidateErrors =
  (requestError) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const errors = requestError?.response?.data?.message;
    const filteredErrors = filterDeepErrorsByField(errors, PROJECTS_FIELD_ERRORS);

    if (filteredErrors.length > 0) {
      filteredErrors.forEach((err) => {
        dispatch(
          profileProjectsSlice.actions.setErrors({
            ...getState().profileProjects.errors,
            ...err,
          }),
        );
      });

      dispatch(profileProjectsSlice.actions.setIsProjectEdit(true));
    }
  };

export default profileProjectsSlice;
