// @ts-nocheck

// TODO: типизировать

import { FIELDS } from './constants';

import { ChangeFieldStateParams, ValidateObj, ValidateAndSubmitParams } from '@commonTypes/type';

export const pattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

export const linkPattern = /^(https?:\/\/)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/;

interface ValidateFieldProps {
  field: string;
  value: string;
  validateHandler: ({ field, value }: ChangeFieldStateParams) => void;
  isRequired?: boolean;
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export default {
  email: validateEmail,
};

export const validateField = ({
  field,
  value,
  validateHandler,
  isRequired = false,
}: ValidateFieldProps) => {
  switch (field) {
    case FIELDS.COMMUNITY:
      {
        if (isRequired && !value) {
          validateHandler({ field, value: 'Введите название' });
        } else {
          if (!value) {
            validateHandler({ field, value: '' });
          } else {
            if (value.length < 2 || value.length > 48) {
              validateHandler({ field, value: 'от 2 до 48 символов' });
            } else {
              validateHandler({ field, value: '' });
            }
          }
        }
      }
      break;
    case FIELDS.POSITION:
      {
        if (!value) {
          validateHandler({ field, value: '' });
        } else {
          if (value.length > 81) {
            validateHandler({ field, value: 'Максимальное количество символов - 80' });
          } else if (value.length < 2) {
            validateHandler({ field, value: 'Минимальное количество символов - 2' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
    case FIELDS.NAME:
      {
        const cyrillicRegExp = /^[а-яёА-ЯЁ\s]+$/;
        if (isRequired && !value) {
          validateHandler({ field, value: 'Укажите имя' });
        } else {
          if (!value) {
            validateHandler({ field, value: 'Укажите имя' });
          }

          if (value.length < 2 || value.length > 39) {
            validateHandler({ field, value: 'От 2 до 39 символов' });
          } else if (value === '') {
            validateHandler({ field, value: 'Укажите имя' });
          } else if (!cyrillicRegExp.test(value)) {
            validateHandler({ field, value: 'В написании имени используйте кириллицу' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
    case FIELDS.LASTNAME:
      {
        const cyrillicRegExp = /^[а-яёА-ЯЁ\s]+$/;
        if (isRequired && !value) {
          validateHandler({ field, value: 'Укажите фамилию' });
        } else {
          if (!value) {
            validateHandler({ field, value: 'Укажите фамилию' });
          }

          if (value.length < 2 || value.length > 39) {
            validateHandler({ field, value: 'От 2 до 39 символов' });
          } else if (value === '') {
            validateHandler({ field, value: 'Укажите фамилию' });
          } else if (!cyrillicRegExp.test(value)) {
            validateHandler({ field, value: 'В написании фамилии используйте кириллицу' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
    case FIELDS.PHONE:
      {
        if (isRequired && !value) {
          validateHandler({ field, value: 'Укажите номер телефона' });
        } else {
          const formatPhoneValue = value.replace(/\D/g, '');

          if (formatPhoneValue.length < 11) {
            validateHandler({ field, value: 'Введен некорректный номер телефона' });
          } else if (!formatPhoneValue.length) {
            validateHandler({ field, value: 'Укажите номер телефона' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
    case FIELDS.DESCRIPTION:
      {
        if (isRequired && !value) {
          validateHandler({ field, value: 'Введите описание' });
        } else {
          if (!value) {
            validateHandler({ field, value: '' });
          } else if (value.length < 15 || value.length > 280) {
            validateHandler({ field, value: 'от 15 до 280 символов' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
    case FIELDS.EMAIL:
      {
        if (isRequired && !value) {
          validateHandler({ field, value: 'Укажите почту' });
        } else {
          if (!value) {
            validateHandler({ field, value: '' });
          } else if (value.length > 50) {
            validateHandler({ field, value: 'Максимальное количество символов - 50' });
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            validateHandler({ field, value: 'Введен некорректный email' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
    case FIELDS.TELEGRAM:
      {
        if (!value) {
          validateHandler({ field, value: '' });
        } else {
          if (value.length < 5) {
            validateHandler({ field, value: 'Минимальная длина 5 символов' });
          } else if (value.length > 32) {
            validateHandler({ field, value: 'Максимальная длина 32 символа' });
          } else if (/[^a-zA-Z0-9_]/g.test(value)) {
            validateHandler({ field, value: 'Допустимые символы a-z0-9_' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
    case FIELDS.RESIDENTS_COUNT:
      {
        Number(value) > 1000000
          ? validateHandler({ field, value: 'Максимальное количество резидентов 999 999 человек' })
          : validateHandler({ field, value: '' });
      }
      break;
    case FIELDS.WEB_SITE:
      {
        if (isRequired && !value) {
          validateHandler({ field, value: 'Вставьте ссылку' });
        } else {
          linkPattern.test(value)
            ? validateHandler({ field, value: '' })
            : validateHandler({ field, value: 'Введен некорректный адрес сайта' });
        }
      }
      break;
    case FIELDS.CITY:
      {
        !value
          ? validateHandler({ field, value: 'Укажите город' })
          : validateHandler({ field, value: '' });
      }
      break;
    case FIELDS.BDATE:
      {
        if (!value || value.length < 10) {
          validateHandler({ field, value: 'Укажите дату рождения' });
        } else {
          const currentDate = new Date();
          const lastTenYear = currentDate.getFullYear() - 18;
          const lastOneHundredYear = currentDate.getFullYear() - 100;

          const year = value.split('.')[2];

          if (year && year.length === 4) {
            if (Number(year) < 1950) {
              validateHandler({
                field,
                value: `дата рождения не может быть раньше ${lastOneHundredYear}`,
              });
            } else if (Number(year) >= Number(lastTenYear)) {
              validateHandler({ field, value: `дата рождения не может быть позже ${lastTenYear}` });
            } else {
              validateHandler({ field, value: '' });
            }
          }
        }
      }
      break;
    case FIELDS.PROJECT_NAME:
      {
        if (isRequired && !value) {
          validateHandler({ field, value: 'Введите название' });
        } else {
          if (value.length < 2 || value.length > 80) {
            validateHandler({ field, value: 'от 2 до 80 символов' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
    case FIELDS.RESOURCE_MODAL_TITLE:
      {
        if (isRequired && !value) {
          validateHandler({ field, value: 'Поле обязательно для заполнения' });
        } else if (!isRequired && !value) {
          validateHandler({ field, value: '' });
        } else {
          if (value.length < 2 || value.length > 80) {
            validateHandler({ field, value: 'от 2 до 80 символов' });
          } else {
            validateHandler({ field, value: '' });
          }
        }
      }
      break;
  }
};

export const validateBeforeSubmit = ({
  field,
  value,
  isRequired,
}: {
  field: string;
  value: string;
  isRequired?: boolean;
}) => {
  switch (field) {
    case FIELDS.NAME: {
      const cyrillicRegExp = /^[а-яёЕЁА-Я\s]+$/;

      if (isRequired && !value) {
        return { field, value: 'Укажите имя' };
      } else {
        if (!value) {
          return { field, value: 'Укажите имя' };
        }

        if (value.length < 2 || value.length > 39) {
          return { field, value: 'От 2 до 39 символов' };
        } else if (value === '') {
          return { field, value: 'Укажите имя' };
        } else if (!cyrillicRegExp.test(value)) {
          return { field, value: 'В написании имени используйте кириллицу' };
        } else {
          return { field, value: '' };
        }
      }
    }
    case FIELDS.LASTNAME: {
      const cyrillicRegExp = /^[а-яёЕЁА-Я\s]+$/;
      if (isRequired && !value) {
        return { field, value: 'Укажите фамилию' };
      } else {
        if (!value) {
          return { field, value: 'Укажите фамилию' };
        }

        if (value.length < 2 || value.length > 39) {
          return { field, value: 'От 2 до 39 символов' };
        } else if (value === '') {
          return { field, value: 'Укажите фамилию' };
        } else if (!cyrillicRegExp.test(value)) {
          return { field, value: 'В написании фамилии используйте кириллицу' };
        } else {
          return { field, value: '' };
        }
      }
    }
    case FIELDS.DESCRIPTION: {
      if (isRequired && !value) {
        return { field, value: 'Введите описание' };
      } else {
        if (!value) {
          return { field, value: '' };
        } else if (value.length < 15 || value.length > 280) {
          return { field, value: 'от 15 до 280 символов' };
        } else {
          return { field, value: '' };
        }
      }
    }
    case FIELDS.BDATE:
      {
        if (!value || value.length < 10) {
          return { field, value: 'Укажите дату рождения' };
        } else {
          const currentDate = new Date();
          const lastTenYear = currentDate.getFullYear() - 18;
          const lastOneHundredYear = currentDate.getFullYear() - 100;

          const year = value.split('.')[2];

          if (year && year.length === 4) {
            if (Number(year) < 1950) {
              return {
                field,
                value: `дата рождения не может быть раньше ${lastOneHundredYear}`,
              };
            } else if (Number(year) >= Number(lastTenYear)) {
              return { field, value: `дата рождения не может быть позже ${lastTenYear}` };
            } else {
              return { field, value: '' };
            }
          }
        }
      }
      break;
    case FIELDS.CITY: {
      return !value ? { field, value: 'Укажите город' } : { field, value: '' };
    }
    case FIELDS.WEB_SITE: {
      if (isRequired && !value) {
        return { field, value: 'Вставьте ссылку' };
      } else {
        return linkPattern.test(value)
          ? { field, value: '' }
          : { field, value: 'Введен некорректный адрес сайта' };
      }
    }
    case FIELDS.RESOURCE_MODAL_TITLE: {
      if (isRequired && !value) {
        return { field, value: 'Поле обязательно для заполнения' };
      } else if (!isRequired && !value) {
        return { field, value: '' };
      } else {
        if (value.length < 2 || value.length > 80) {
          return { field, value: 'от 2 до 80 символов' };
        } else {
          return { field, value: '' };
        }
      }
    }
  }
};

export const filterErrorsByField = (errors, fieldsErrors) => {
  return Object.keys(fieldsErrors).reduce((acc, key) => {
    if (errors.includes(key)) {
      acc[key] = fieldsErrors[key];
    }
    return acc;
  }, {});
};

export const filterDeepErrorsByField = (errors, fieldsErrors) => {
  return errors.reduce((acc, obj) => {
    // eslint-disable-next-line no-prototype-builtins
    if (fieldsErrors.hasOwnProperty(obj.property)) {
      const fieldErrors = fieldsErrors[obj.property];
      if (typeof fieldErrors === 'object' && obj.constraints) {
        const constraints = Object.keys(obj.constraints);
        const existingConstraints = constraints.filter((constraint) =>
          // eslint-disable-next-line no-prototype-builtins
          fieldErrors.hasOwnProperty(constraint),
        );
        if (existingConstraints.length > 0) {
          const constraintValues = existingConstraints.map((constraint) => ({
            [obj.property]: fieldErrors[constraint],
          }));
          return [...acc, ...constraintValues];
        }
      }
      return [...acc, { [obj.property]: fieldErrors }];
    }
    return acc;
  }, []);
};

export const isDisableButton = (errors: ValidateObj): boolean => {
  return Object.values(errors).some((value) => value !== '');
};

// Для обязательных полей в конце value - указываем *
export const validateAndSubmit = ({
  validateObj,
  changeErrors,
  submit,
}: ValidateAndSubmitParams) => {
  const errors = [];
  for (const [field, value] of Object.entries(validateObj)) {
    const err = validateBeforeSubmit({
      field,
      value: value && value.includes('*') ? value.replace('*', '') : value,
      isRequired: value && value.includes('*'),
    });

    if (err.value) {
      changeErrors({ field: err.field, value: err.value });
      errors.push(err);
    }
  }

  if (errors.length) return;

  submit();
};
