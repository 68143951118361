import { createSlice } from '@reduxjs/toolkit';

const verificationSlice = createSlice({
  name: 'verification',
  initialState: {
    isShowDateModal: false,
  },
  reducers: {
    setIsShowDateModal: (state, { payload }) => {
      state.isShowDateModal = payload;
    },
  },
});

export default verificationSlice;
