import { createSlice } from '@reduxjs/toolkit';

import { UIState } from './types';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isShowInvite: false,
    isActiveMenuItem: null,
  } as UIState,
  reducers: {
    setIsShowInvite: (state, { payload }: { payload: boolean }) => {
      state.isShowInvite = payload;
    },
    setIsActiveMenuItem: (state, { payload }: { payload: number | null }) => {
      state.isActiveMenuItem = payload;
    },
  },
});

export default uiSlice;
