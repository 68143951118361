import { format, isSameYear, isSameMonth, isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';

export const formatEventDate = (start: string, finish: string) => {
  const startDate = new Date(start);

  if (!finish) {
    return (
      `${format(startDate, 'd', { locale: ru })} ` +
      `${format(startDate, 'MMMM', { locale: ru })} ` +
      `${format(startDate, 'yyyy', { locale: ru })} в ${format(startDate, 'HH:mm', { locale: ru })}`
    );
  }

  const endDate = new Date(finish);

  // Проверяем, если годы разные
  if (!isSameYear(startDate, endDate)) {
    return `${format(startDate, 'dd.MM.yyyy', { locale: ru })} - ${format(endDate, 'dd.MM.yyyy', { locale: ru })} в ${format(endDate, 'HH:mm', { locale: ru })}`;
  }

  // Проверяем, если месяцы разные
  if (!isSameMonth(startDate, endDate)) {
    return `${format(startDate, 'd', { locale: ru })} - ${format(endDate, 'd', { locale: ru })} ${format(startDate, 'MMMM', { locale: ru })} ${format(startDate, 'yyyy', { locale: ru })} в ${format(startDate, 'HH:mm', { locale: ru })}`;
  }

  // Проверяем, если дни одинаковые
  if (isSameDay(startDate, endDate)) {
    return `${format(startDate, 'd', { locale: ru })} ${format(startDate, 'MMMM', { locale: ru })} ${format(startDate, 'yyyy', { locale: ru })} в ${format(startDate, 'HH:mm', { locale: ru })}`;
  }

  // Если дни разные
  return `${format(startDate, 'd', { locale: ru })} - ${format(endDate, 'd', { locale: ru })} ${format(startDate, 'MMMM', { locale: ru })} ${format(startDate, 'yyyy', { locale: ru })} в ${format(startDate, 'HH:mm', { locale: ru })}`;
};
