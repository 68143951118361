// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import profileSlice from '@store/profile/profile';
import { specialistLevelItems } from '@components/services/hrsm/blocks/VacancyFilter/utils';

import { VacancyState, Vacancy } from './types';

export const vacancySlice = createSlice({
  name: 'vacancy',
  initialState: {
    profSpheres: [],
    filterOptions: undefined,
    vacancies: [],
    myVacancies: [],
    user: null,
    isLoading: false,
  } as VacancyState,
  reducers: {
    setFilterOptions(state, action) {
      const vacancies = action.payload.vacancies;
      const profSpheres = action.payload.profSpheres;

      state.filterOptions = [
        {
          label: 'отрасль',
          id: 'industry',
          items: profSpheres.map((sphere) => ({
            ...sphere,
            amount: vacancies.reduce(
              (accum, vacancy) =>
                vacancy.fields.find((field) => field.id === sphere.id) ? accum + 1 : accum,
              0,
            ),
          })),
        },
        {
          label: 'уровень специалиста',
          id: 'specialist level',
          items: specialistLevelItems.map((item) => ({
            ...item,
            amount: vacancies.reduce(
              (accum, vacancy) => (vacancy.vacancyLevel === item.id ? accum + 1 : accum),
              0,
            ),
          })),
        },
      ];
    },
    setProfSpheres(state, action) {
      state.profSpheres = action.payload;
    },
    setVacancies(state, action) {
      state.vacancies = action.payload;
    },
    setMyVacancies(state, { payload }: { payload: Vacancy[] }) {
      state.myVacancies = payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setIsLoading(state, { payload }: { payload: boolean }) {
      state.isLoading = payload;
    },
  },
});

export const { setVacancies, setFilterOptions, setProfSpheres, setMyVacancies } =
  vacancySlice.actions;

export const fetchVacancies = (bizFields, vacancyLevel, userId) => async (dispatch, getState) => {
  const state = getState();
  const response = await axios.get('/hrms/vacancies/', {
    params: { bizFields, vacancyLevel, userId: userId || state.vacancy.userId },
  });

  dispatch(setVacancies(response.data.vacancies));
  dispatch(vacancySlice.actions.setUser(response.data.user));

  let profSpheres = state.vacancy.profSpheres;
  if (!profSpheres.length) {
    const profSpheresResponce = await axios.get('/profile/job-fields/');
    dispatch(setProfSpheres(profSpheresResponce.data));
    profSpheres = profSpheresResponce.data;
  }

  dispatch(setFilterOptions({ profSpheres, vacancies: response.data.vacancies }));
};

export const fetchProfSpheres = () => async (dispatch, getState) => {
  const state = getState();
  if (state.vacancy.profSpheres.length) return;
  const response = await axios.get('/hrms/prof-spheres/');
  dispatch(setProfSpheres(response.data.profSpheres));
};

export const fetchMyVacancies = (id, isArchive) => async (dispatch) => {
  dispatch(vacancySlice.actions.setIsLoading(true));

  try {
    const response = await axios.get(`/hrms/vacancies?userId=${id}&isArchive=${isArchive}`);

    if (response?.data) {
      dispatch(setMyVacancies(response.data.vacancies));

      dispatch(vacancySlice.actions.setIsLoading(false));
    }
  } catch (e) {
    dispatch(vacancySlice.actions.setIsLoading(false));
  } finally {
    dispatch(vacancySlice.actions.setIsLoading(false));
  }
};

export const archiveMyVacancy = (vacancyId, userId, isArchive) => async (dispatch) => {
  await axios.patch(`/hrms/vacancies/${vacancyId}/archive/`);

  dispatch(fetchMyVacancies(userId, isArchive));
};

export const unarchiveMyVacancy = (vacancyId, userId, isArchive) => async (dispatch) => {
  await axios.patch(`/hrms/vacancies/${vacancyId}/unarchive/`);

  dispatch(fetchMyVacancies(userId, isArchive));
};

export const restoreMyVacancy = (vacancyId, userId, isArchive) => async (dispatch) => {
  const response = await axios.patch(`/hrms/vacancies/${vacancyId}/restore/`);

  dispatch(fetchMyVacancies(userId, isArchive));
  dispatch(
    profileSlice.actions.updateProfilePartial({
      canCreateVacancy: response.data.canCreateVacancy,
    }),
  );
};

export const deleteMyVacancy = (vacancyId, userId) => async (dispatch) => {
  const response = await axios.delete(`/hrms/vacancies/${vacancyId}/`);
  dispatch(fetchMyVacancies(userId, true));
  dispatch(
    profileSlice.actions.updateProfilePartial({
      canCreateVacancy: response.data.canCreateVacancy,
    }),
  );
};

export default vacancySlice;
