import { formatEventDate } from '@utils/formatEventDate';

import {
  ValidateRegisterEventBeforeSubmit,
  Calendar,
  CalendarEvent,
  EventResponse,
  FindCalendarParams,
} from './types';

export const formatCalendars = (calendars: Calendar[]) => {
  return calendars.map((calendar) => ({
    ...calendar,
    title: calendar.community.title,
  }));
};

export const createCalendarEvents = (events: EventResponse[]): CalendarEvent[] => {
  const filteredEvents = events.filter((calendarEvent) => {
    return calendarEvent.recurring
      ? calendarEvent
      : new Date(calendarEvent.start).getTime() > new Date().getTime();
  });

  const sortedEvents = filteredEvents.sort(
    (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime(),
  );

  return sortedEvents.map((item) => ({
    eventId: item.id,
    title: item.title,
    date: formatEventDate(item.start, item.finish),
    isOnline: item.isOnline,
    city: item.city,
    startTime: 0,
    start: item.start,
    finish: item.finish,
    rrule: item.rrule,
    hash: item.hash,
    recurring: item.recurring,
    description: item.description,
    link: item.link,
    type: item.type,
    area: item.area,
    place: item.place,
    image: item.image,
    sponsor: item.sponsor,
    isChecked: false,
    wasRegister: false,
    price: item.price,
  }));
};

export const createCalendarEvent = ({
  event,
  wasRegister,
}: {
  event: EventResponse;
  wasRegister: boolean;
}): CalendarEvent => {
  return {
    eventId: event.id,
    title: event.title,
    date: formatEventDate(event.start, event.finish),
    isOnline: event.isOnline,
    city: event.city,
    startTime: 0,
    start: event.start,
    finish: event.finish,
    rrule: event.rrule,
    hash: event.hash,
    recurring: event.recurring,
    description: event.description,
    link: event.link,
    type: event.type,
    area: event.area,
    place: event.place,
    image: event.image,
    sponsor: event.sponsor,
    isChecked: false,
    price: event.price,
    wasRegister,
  };
};

export const findCalendar = ({
  calendars,
  communityTitle,
}: FindCalendarParams): Calendar | undefined => {
  return calendars.find(
    (calendar) =>
      calendar.community.title.trim().toLowerCase() === communityTitle.trim().toLowerCase(),
  );
};

export const validateRegisterEventBeforeSubmit = ({
  guests,
  setErrors,
}: ValidateRegisterEventBeforeSubmit) => {
  guests.forEach((guest) => {
    if (!guest.name) {
      setErrors({
        guestId: guest.id,
        field: 'name',
        value: 'Поле обязательно для заполнения',
      });
    }

    if (guest.name && (guest.name.length < 2 || guest.name.length > 80)) {
      setErrors({
        guestId: guest.id,
        field: 'name',
        value: 'Поле обязательно для заполнения',
      });
    }

    if (!guest.lastname) {
      setErrors({
        guestId: guest.id,
        field: 'lastname',
        value: 'Поле обязательно для заполнения',
      });
    }

    if (guest.lastname && (guest.lastname.length < 2 || guest.lastname.length > 80)) {
      setErrors({
        guestId: guest.id,
        field: 'lastname',
        value: 'Поле обязательно для заполнения',
      });
    }

    if (!guest.phone) {
      setErrors({
        guestId: guest.id,
        field: 'phone',
        value: 'Укажите номер телефона',
      });
    }

    if (guest.phone) {
      const formatPhoneValue = guest.phone.replace(/\D/g, '');

      if (formatPhoneValue.length < 11) {
        setErrors({
          guestId: guest.id,
          field: 'phone',
          value: 'Введен некорректный номер телефона',
        });
      }
    }
  });
};
