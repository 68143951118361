import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import notificationsSlice from '@store/notification/notifications';
import { acceptContact } from '@store/club/club';

import { AppDispatch, RootState } from '@store/index';
import { InterviewState, InterviewData } from './types';

export const interviewSlice = createSlice({
  name: 'interview',
  initialState: {
    isShowInterviewSuccessModal: false,
    textTime: '',
    textDate: '',
    oldInterviewData: {},
  } as InterviewState,
  reducers: {
    setIsShowInterviewSuccessModal: (state, { payload }) => {
      state.isShowInterviewSuccessModal = payload;
    },
    setTextTime: (state, { payload }) => {
      state.textTime = payload;
    },
    setTextDate: (state, { payload }) => {
      state.textDate = payload;
    },
    setOldInterviewData: (state, { payload }: { payload: InterviewData }) => {
      state.oldInterviewData = payload;
    },
    resetInterviewModal: (state) => {
      state.isShowInterviewSuccessModal = false;
      state.textTime = '';
      state.textDate = '';
      state.oldInterviewData = {};
    },
  },
});

export const fetchInterviewData = () => async (dispatch: AppDispatch) => {
  const response = await axios.get('/interview-requests');

  if (response?.status === 200 && response?.data?.id) {
    dispatch(interviewSlice.actions.setOldInterviewData(response.data));
  }
};

export const updateInterviewData =
  (data: InterviewData) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(acceptContact(getState().club.actionUrl));

    try {
      const response = await axios.put('/interview-requests', data);

      if (response?.status === 200) {
        dispatch(notificationsSlice.actions.setIsShowInterviewModal(false));
        dispatch(interviewSlice.actions.setIsShowInterviewSuccessModal(true));
      }
    } catch (e) {
      // TODO: handle error
    }
  };

export default interviewSlice;
