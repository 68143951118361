import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import commonSlice from '@store/common/common';

import { randomUserIds } from './utils';

import { AppDispatch } from '@store/index';
import {
  ServicesState,
  Manager,
  Profile,
  SearchParams,
  UpdateVisitingCardParams,
  VisitingCard,
  OldVisitingData,
  FilledVisitingCards,
  VisitingCardUrlParams,
} from './types';

export const servicesSlice = createSlice({
  name: 'services',
  initialState: {
    services: [
      { name: 'system', notifications: 0, title: 'системные инициативы' },
      { name: 'club', notifications: 0, title: 'Ресурсная карта' },
      { name: 'calendar', notifications: 0, title: 'календарь событий' },
      { name: 'hrsm', notifications: 0, title: 'управление персоналом' },
    ],
    isShowServices: false,
    isShowServiceInfo: false,
    isTabletShowServiceInfo: false,
    isOnlyOneCommunity: false,
    isOnlyOneModeration: false,
    infoModalPosX: 0,
    infoModalPosY: 0,
    isLoading: true,
    isVisitingCardModal: false,
    profileData: {},
    isVisitingHint: true,
    currentCommunityId: 0,
    sphereOptions: [],
    competenceOptions: [],
    lobbyOptions: [],
    competences: [],
    lobbies: [],
    spheres: [],
    oldVisitingCardData: {},
    filledVisitingCards: [],
    isEmptySpheres: false,
    isEmptyCompetences: false,
  } as ServicesState,
  reducers: {
    setIsShowServices: (state, { payload }: { payload: boolean }) => {
      state.isShowServices = payload;
    },
    setIsShowServiceInfo: (state, { payload }: { payload: boolean }) => {
      state.isShowServiceInfo = payload;
    },
    setIsTabletShowServiceInfo: (state, { payload }: { payload: boolean }) => {
      state.isTabletShowServiceInfo = payload;
    },
    setIsOnlyOneCommunity: (state, { payload }: { payload: boolean }) => {
      state.isOnlyOneCommunity = payload;
    },
    setIsOnlyOneModeration: (state, { payload }: { payload: boolean }) => {
      state.isOnlyOneModeration = payload;
    },
    setInfoModalPosX: (state, { payload }: { payload: number }) => {
      state.infoModalPosX = payload;
    },
    setInfoModalPosY: (state, { payload }: { payload: number }) => {
      state.infoModalPosY = payload;
    },
    setIsLoading: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
    setProfileData: (state, { payload }: { payload: Partial<Profile> }) => {
      state.profileData = payload;
    },
    setIsVisitingCardModal: (state, { payload }: { payload: boolean }) => {
      state.isVisitingCardModal = payload;
    },
    setIsVisitingHint: (state, { payload }: { payload: boolean }) => {
      state.isVisitingHint = payload;
    },
    setCurrentCommunityId: (state, { payload }: { payload: number }) => {
      state.currentCommunityId = payload;
    },
    setSphereOptions: (state, { payload }: { payload: string[] }) => {
      state.sphereOptions = payload;
    },
    setCompetenceOptions: (state, { payload }: { payload: string[] }) => {
      state.competenceOptions = payload;
    },
    setLobbyOptions: (state, { payload }: { payload: string[] }) => {
      state.lobbyOptions = payload;
    },
    setSpheres: (state, { payload }: { payload: string[] }) => {
      state.spheres = payload;
    },
    setCompetences: (state, { payload }: { payload: string[] }) => {
      state.competences = payload;
    },
    setLobbies: (state, { payload }: { payload: string[] }) => {
      state.lobbies = payload;
    },
    setOldVisitingCardData: (state, { payload }: { payload: Partial<OldVisitingData> }) => {
      state.oldVisitingCardData = payload;
    },
    setFilledVisitingCards: (state, { payload }: { payload: FilledVisitingCards[] }) => {
      state.filledVisitingCards = payload;
    },
    setIsEmptySpheres: (state, { payload }: { payload: boolean }) => {
      state.isEmptySpheres = payload;
    },
    setIsEmptyCompetences: (state, { payload }: { payload: boolean }) => {
      state.isEmptyCompetences = payload;
    },
    resetVisitingCard: (state) => {
      state.spheres = [];
      state.competences = [];
      state.lobbies = [];
      state.sphereOptions = [];
      state.competenceOptions = [];
      state.lobbyOptions = [];
      state.oldVisitingCardData = {};
      state.isEmptySpheres = false;
      state.isEmptyCompetences = false;
    },
    reset: (state) => {
      state.isShowServiceInfo = false;
      state.isTabletShowServiceInfo = false;
      state.isOnlyOneCommunity = false;
      state.isOnlyOneModeration = false;
      state.infoModalPosX = 0;
      state.infoModalPosY = 0;
      state.isLoading = false;
      state.profileData = {};
    },
  },
});

export const fetchCommunityManagers = (communityId: number) => async (dispatch: AppDispatch) => {
  dispatch(servicesSlice.actions.setIsLoading(false));

  const response = await axios.get(`/communities/${communityId}/managers`);

  if (response?.status === 200 && response.data?.length > 0) {
    const profileIDs = response.data.map((item: Manager) => item.profileId);
    const randomCommunityIndex = randomUserIds(profileIDs);

    const managerResponse = await axios.get(`/v2/profile/${randomCommunityIndex}`);

    if (managerResponse.data) {
      const profileFirstName = managerResponse.data.profile.firstName;
      const profileLastName = managerResponse.data.profile.lastName;

      dispatch(
        servicesSlice.actions.setProfileData({
          profileName: `${profileFirstName} ${profileLastName}`,
          profileId: managerResponse.data.profile.id,
        }),
      );
      dispatch(servicesSlice.actions.setIsLoading(true));
    }
  }
};

export const fetchVisitingCard = (communityId: number) => async (dispatch: AppDispatch) => {
  dispatch(servicesSlice.actions.setIsLoading(true));

  try {
    const response = await axios.get<VisitingCard>(`/club/cards?comId=${communityId}`);

    if (response.data) {
      const formatTelegram = (tg: string[] | null): string => {
        if (!tg) return '';

        const arrayAsString = tg.toString();

        return arrayAsString.includes('@') ? arrayAsString.replace('@', '') : arrayAsString;
      };

      dispatch(servicesSlice.actions.setSpheres(response.data.allSpheres));
      dispatch(servicesSlice.actions.setCompetences(response.data.allCompetences));
      dispatch(servicesSlice.actions.setLobbies(response.data.allLobbies));
      dispatch(
        servicesSlice.actions.setOldVisitingCardData({
          telegrams: formatTelegram(response.data.telegrams),
          phones: response.data.phones.length ? response.data.phones.toString() : '',
          websites: response.data.websites ? response.data.websites.toString() : '',
        }),
      );
    }
  } finally {
    dispatch(servicesSlice.actions.setIsLoading(false));
  }
};

export const fetchFilledVisitingCards = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get<FilledVisitingCards[]>('/club/communities');

    if (response.data) {
      dispatch(servicesSlice.actions.setFilledVisitingCards(response.data));
    }
  } catch (err) {
    // TODO: написать экзепшены
  }
};

export const searchSpheres =
  ({ search, communityId }: SearchParams) =>
  async (dispatch: AppDispatch) => {
    let urlParams: VisitingCardUrlParams = {};

    if (search) {
      urlParams = {
        search,
      };
    }

    try {
      const response = await axios.get(`/club/spheres?comId=${communityId}`, { params: urlParams });

      if (response.status === 200) {
        dispatch(servicesSlice.actions.setSphereOptions(response.data));
      }
    } catch (e) {
      // TODO: написать исключения
    }
  };

export const searchCompetence =
  ({ search, communityId }: SearchParams) =>
  async (dispatch: AppDispatch) => {
    let urlParams: VisitingCardUrlParams = {};

    if (search) {
      urlParams = {
        search,
      };
    }

    try {
      const response = await axios.get(`/club/competences?comId=${communityId}`, {
        params: urlParams,
      });

      if (response.status === 200) {
        dispatch(servicesSlice.actions.setCompetenceOptions(response.data));
      }
    } catch (e) {
      // TODO: написать исключения
    }
  };

export const searchUsefulContacts =
  ({ search, communityId }: SearchParams) =>
  async (dispatch: AppDispatch) => {
    let urlParams: VisitingCardUrlParams = {};

    if (search) {
      urlParams = {
        search,
      };
    }

    try {
      const response = await axios.get(`/club/lobbies?comId=${communityId}`, { params: urlParams });

      if (response.status === 200) {
        dispatch(servicesSlice.actions.setLobbyOptions(response.data));
      }
    } catch (e) {
      // TODO: написать исключения
    }
  };

export const updateVisitingCard =
  (params: UpdateVisitingCardParams) => async (dispatch: AppDispatch) => {
    const data = Object.fromEntries(
      Object.entries(params.data).filter(([key, value]) => key && value !== null),
    );

    const correctData = {
      ...data,
      telegrams: data.telegrams ? data.telegrams : [],
    };

    try {
      const response = await axios.patch(`/club/cards?comId=${params.communityId}`, correctData);

      if (response.status === 200) {
        dispatch(servicesSlice.actions.setIsVisitingCardModal(false));
        dispatch(commonSlice.actions.setShowMobileMenu(true));
        dispatch(servicesSlice.actions.resetVisitingCard());
        dispatch(fetchFilledVisitingCards());
      }
    } catch (err) {
      // TODO: написать исключения
    }
  };

export default servicesSlice;
