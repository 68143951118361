import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { createLinks } from './utils';

import { AppDispatch } from '@store/index';
import { AttachmentLinksState, FetchAttachmentLinksResponse, Link } from './types';

export const attachmentLinksSlice = createSlice({
  name: 'attachmentLinksStore',
  initialState: {
    isFetchingLinks: false,
    links: [],
  } as AttachmentLinksState,
  reducers: {
    setIsFetchingLinks: (state, { payload }: { payload: boolean }) => {
      state.isFetchingLinks = payload;
    },
    setLinks: (state, { payload }: { payload: Link[] }) => {
      state.links = payload;
    },
    resetState: (state) => {
      state.isFetchingLinks = false;
      state.links = [];
    },
  },
});

export const fetchAttachmentLinks = (communityId: number) => async (dispatch: AppDispatch) => {
  dispatch(attachmentLinksSlice.actions.setIsFetchingLinks(true));

  try {
    const response = await axios.get<FetchAttachmentLinksResponse[]>(
      `/communities/links/public?communityId=${communityId}`,
    );

    if (response.data) {
      dispatch(attachmentLinksSlice.actions.setLinks(createLinks(response.data)));
    }
  } catch (_e) {
    console.log('Не удалось запросить прикрепленные ссылки');
    dispatch(attachmentLinksSlice.actions.setLinks([]));
  } finally {
    dispatch(attachmentLinksSlice.actions.setIsFetchingLinks(false));
  }
};

export default attachmentLinksSlice;
