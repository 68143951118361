import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';
import { ClubInviteState, SendInviteParams } from './types';

export const clubInviteSlice = createSlice({
  name: 'clubInvite',
  initialState: {
    isShowClubInviteModal: false,
    isShowChangeInviteModal: false,
    contact: null,
  } as ClubInviteState,
  reducers: {
    setIsShowClubInviteModal: (state, { payload }) => {
      state.isShowClubInviteModal = payload;
    },
    setIsShowChangeInviteModal: (state, { payload }) => {
      state.isShowChangeInviteModal = payload;
    },
    setContact: (state, { payload }) => {
      state.contact = payload;
    },
  },
});

export const requestContacts = (key: string) => async (dispatch: AppDispatch) => {
  const response = await axios.get('/club/request-contacts', {
    params: { key },
  });

  dispatch(clubInviteSlice.actions.setContact(response.data));
};

export const sendInvite = (data: SendInviteParams) => async () => {
  await axios.post('/club/send-contacts/', data);
};

export default clubInviteSlice;
