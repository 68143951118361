import { FetchDefaultSubscriptionResponse, DefaultSubscription } from './types';

export const createDefaultSubscription = (
  subscription: FetchDefaultSubscriptionResponse | null,
): DefaultSubscription | null => {
  if (!subscription) return null;

  return {
    amount: `${formatNumberWithSpaces(subscription.amount)} ₽`,
    period: subscription.period > 9 ? 'год' : 'мес',
  };
};

export const formatNumberWithSpaces = (amount: number): string => {
  return amount.toLocaleString('ru-RU', { useGrouping: true }).replace(/,/g, ' ');
};
