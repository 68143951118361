import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';
import { TelegramBotState, BotStatus } from './types';

const telegramBotSlice = createSlice({
  name: 'telegramBot',
  initialState: {
    isLoadingTelegramBot: false,
    botStatus: { isActive: false },
  } as TelegramBotState,
  reducers: {
    setIsLoadingTelegramBot: (state, { payload }: { payload: boolean }) => {
      state.isLoadingTelegramBot = payload;
    },
    setBotStatus: (state, { payload }: { payload: BotStatus }) => {
      state.botStatus = payload;
    },
  },
});

export const fetchTelegramBotStatus = () => async (dispatch: AppDispatch) => {
  dispatch(telegramBotSlice.actions.setIsLoadingTelegramBot(true));

  try {
    const response = await axios.get('/bot/status');
    dispatch(telegramBotSlice.actions.setBotStatus(response.data));
  } finally {
    dispatch(telegramBotSlice.actions.setIsLoadingTelegramBot(false));
  }
};

export default telegramBotSlice;
