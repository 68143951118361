import { createSlice } from '@reduxjs/toolkit';
import axiosLib from 'axios';
import axios from '@utils/axios';

import { filterMembersById, createMembers } from './utils';

import { AppDispatch } from '@store/index';
import {
  CommunityMembersState,
  FetchMembersParams,
  MemberResponseData,
  ProfileResponse,
} from './types';

export const communityMembersSlice = createSlice({
  name: 'communityMembersStore',
  initialState: {
    members: [],
    totalMembers: 0,
    isFetchingMembers: false,
    isLastPage: false,
    isShowParticipantsModal: false,
  } as CommunityMembersState,
  reducers: {
    setIsFetchingMembers: (state, { payload }: { payload: boolean }) => {
      state.isFetchingMembers = payload;
    },
    setMembers: (state, { payload }: { payload: MemberResponseData }) => {
      if (payload.data.length) {
        const firstMemberId = payload.data[0].profileId;

        const hasMemberId = state.members.find((child) => child.profileId === firstMemberId);

        state.members = hasMemberId ? payload.data : [...state.members, ...payload.data];
        state.totalMembers = payload.total;
        state.isLastPage = state.members.length <= state.totalMembers;
      } else {
        if (state.members.length && !payload.data.length) {
          state.members = [...state.members];
          state.isLastPage = state.members.length <= state.totalMembers;
        } else {
          state.members = [];
          state.totalMembers = 0;
          state.isLastPage = true;
        }
      }
    },
    setIsShowParticipantsModal: (state, { payload }: { payload: boolean }) => {
      state.isShowParticipantsModal = payload;
    },
    resetState: (state) => {
      state.members = [];
      state.totalMembers = 0;
      state.isFetchingMembers = false;
      state.isLastPage = false;
      state.isShowParticipantsModal = false;
    },
  },
});

export const fetchCommunityPageMembers =
  ({ communityId, page = 1, limit = 20, isLastPage }: FetchMembersParams) =>
  async (dispatch: AppDispatch) => {
    if (isLastPage) return null;

    dispatch(communityMembersSlice.actions.setIsFetchingMembers(true));

    try {
      const response = await axios.get<MemberResponseData>(
        `/communities/pages/${communityId}/members`,
        {
          params: { page, limit },
        },
      );

      if (response.data) {
        const memberResponse = response.data as MemberResponseData;

        const filteredResponseMember: MemberResponseData = {
          ...memberResponse,
          data: filterMembersById(memberResponse.data),
        };

        if (!filteredResponseMember.data.length) {
          dispatch(communityMembersSlice.actions.setMembers(memberResponse));
        } else {
          try {
            const memberIds = filteredResponseMember.data.map((member) => member.profileId);

            const responseProfiles = await axiosLib.all(
              memberIds.map((memberId) => axios.get<ProfileResponse>(`/v2/profile/${memberId}`)),
            );

            const correctResponseMembers: MemberResponseData = {
              ...memberResponse,
              data: createMembers(responseProfiles, filteredResponseMember.data),
            };

            dispatch(communityMembersSlice.actions.setMembers(correctResponseMembers));
          } catch (e) {
            console.log('Не удалось получить информацию по участникам сообщества');
          }
        }
      }
    } catch (e) {
      console.log('Не удалось получить участников сообщества');
    } finally {
      dispatch(communityMembersSlice.actions.setIsFetchingMembers(false));
    }
  };

export default communityMembersSlice;
