import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { logout } from '@store/auth/auth';

import { useUsers } from '@hooks/useUsers';

import { AppDispatch } from '@store/index';

const LogoutPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const user = useUsers();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  if (!user) {
    return <Navigate to='/login' />;
  }

  return null;
};

export default LogoutPage;
