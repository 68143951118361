import { Component } from 'react';
import { ErrorPage } from '@pages/errorPage/ErrorPage';

class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <ErrorPage />;
    }

    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;