// @ts-nocheck

// TODO: типизировать

export const removeEmptyFieldValues = (eduArray, isProject?: boolean) => {
  return eduArray.reduce((acc, obj) => {
    const newObj = Object.entries(obj).reduce((newObjAcc, [key, value]) => {
      if (isProject) {
        if (value !== '' && value !== null) {
          newObjAcc[key] = value;
        }
      } else {
        if (value !== '') {
          newObjAcc[key] = value;
        }
      }
      return newObjAcc;
    }, {});

    return acc.concat(newObj);
  }, []);
};
