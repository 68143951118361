export const PERSONAL_FIELD_ERRORS = {
  firstName: 'Введите корректное имя',
  lastName: 'Введите корректную фамилию',
  actualRequest: {
    minLength: 'Минимальное количество символов - 15',
    maxLength: 'Максимальное количество символов - 280',
  },
  bdate: 'Укажите дату рождения',
  city: 'Выберите город',
  about: {
    minLength: 'Минимальное количество символов - 15',
    maxLength: 'Максимальное количество символов - 280',
  },
};
