import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import commonSlice from '../common/common';

import { AppDispatch } from '@store/index';
import { KarmaState, KarmaBonus } from './types';

export const KarmaSlice = createSlice({
  name: 'karma',
  initialState: {
    isKarmaRedirect: false,
    isKarmaRedirectToLogin: false,
    isKarmaRedirectToAbout: false,
    karmaBonuses: null,
  } as KarmaState,
  reducers: {
    setIsKarmaRedirect: (state, { payload }: { payload: boolean }) => {
      state.isKarmaRedirect = payload;
    },
    setIsKarmaRedirectToLogin: (state, { payload }: { payload: boolean }) => {
      state.isKarmaRedirectToLogin = payload;
    },
    setIsKarmaRedirectToAbout: (state, { payload }: { payload: boolean }) => {
      state.isKarmaRedirectToAbout = payload;
    },
    setKarmaBonuses: (state, { payload }: { payload: KarmaBonus[] }) => {
      state.karmaBonuses = payload;
    },
  },
});

// TODO: типизировать fetchKarmaBonuses

export const fetchKarmaBonuses = (userId: number) => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.setLoading(true));

  try {
    const response = await axios.get(`/karma/incomes/${userId}/`);

    if (response?.data) {
      dispatch(KarmaSlice.actions.setKarmaBonuses(response.data));
    }
    dispatch(commonSlice.actions.setLoading(false));
  } catch (e) {
    // TODO handle error
    dispatch(commonSlice.actions.setLoading(false));
  }
};

export default KarmaSlice;
