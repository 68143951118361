// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';

// TODO: типизировать

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    isShowTelegramModal: false,
    allNotifications: [],
    calendarNotifications: [],
    hrsmNotification: [],
    clubNotification: [],
    isShowNotifications: false,
    isShowInterviewModal: false,
    communityTitle: '',
    notificationTabs: [
      { id: 0, name: 'Все', title: 'all', notificationCount: 0 },
      { id: 1, name: 'События', title: 'calendar', notificationCount: 0 },
      { id: 2, name: 'Запросы', title: 'club', notificationCount: 0 },
      { id: 3, name: 'Сервисы', title: 'service', notificationCount: 0 },
    ],
    activeTab: 'all',
    isOpenTgFromSecurity: false,
    isLoading: true,
  },
  reducers: {
    setIsShowTelegramModal: (state, { payload }) => {
      state.isShowTelegramModal = payload;
    },
    setAllNotifications: (state, { payload }) => {
      state.allNotifications = payload;
    },
    setCalendarNotifications: (state, { payload }) => {
      state.calendarNotifications = payload;
    },
    setHRSMNotifications: (state, { payload }) => {
      state.hrsmNotification = payload;
    },
    setClubNotifications: (state, { payload }) => {
      state.clubNotification = payload;
    },
    setIsShowInterviewModal: (state, { payload }) => {
      state.isShowInterviewModal = payload;
    },
    updateNotificationTabs: (state, { payload }) => {
      state.notificationTabs = state.notificationTabs.map((item) => {
        if (item.title === payload.tabName) {
          item.notificationCount = payload.count;
          return item;
        }

        return item;
      });
    },
    setIsShowNotifications: (state, { payload }) => {
      state.isShowNotifications = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.isLoading = true;
      state.activeTab = payload;
    },
    setIsLoading: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
    setIsOpenTgFromSecurity: (state, { payload }: { payload: boolean }) => {
      state.isOpenTgFromSecurity = payload;
    },
  },
});

export const getNotifications = () => async (dispatch: AppDispatch) => {
  const response = await axios.get(`/notifications`);

  if (response.data) {
    const sortedByDate = response.data.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    const hrsm = sortedByDate.filter((item) => item.service === 'hrsm');
    const hrsmNotificationCount = hrsm.filter((item) => !item.isRead).length;
    dispatch(
      notificationsSlice.actions.updateNotificationTabs({
        tabName: 'service',
        count: hrsmNotificationCount,
      }),
    );
    dispatch(notificationsSlice.actions.setHRSMNotifications(hrsm));

    const calendar = sortedByDate.filter((item) => item.service === 'calendar');
    const calendarNotificationCount = calendar.filter((item) => !item.isRead).length;
    dispatch(
      notificationsSlice.actions.updateNotificationTabs({
        tabName: 'calendar',
        count: calendarNotificationCount,
      }),
    );
    dispatch(notificationsSlice.actions.setCalendarNotifications(calendar));

    const club = sortedByDate.filter((item) => item.service === 'club');
    const clubNotificationCount = club.filter((item) => !item.isRead).length;
    dispatch(
      notificationsSlice.actions.updateNotificationTabs({
        tabName: 'club',
        count: clubNotificationCount,
      }),
    );
    dispatch(notificationsSlice.actions.setClubNotifications(club));

    dispatch(notificationsSlice.actions.setAllNotifications(sortedByDate));

    const allNotificationCount = sortedByDate.filter((item) => !item.isRead).length;
    dispatch(
      notificationsSlice.actions.updateNotificationTabs({
        tabName: 'all',
        count: allNotificationCount,
      }),
    );
  }
};

export const readNotification = (id) => async (dispatch: AppDispatch) => {
  const readingIds = { notificationsIds: [id] };

  await axios.patch('/notifications', readingIds);

  dispatch(getNotifications());
};

export default notificationsSlice;
