// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { filterDeepErrorsByField } from '@utils/validate';

import commonSlice from '@store/common/common';
import profileSlice from '@store/profile/profile';

import { AppDispatch, RootState } from '@store/index';

import { PERSONAL_FIELD_ERRORS } from './constants';

// TODO: типизировать

const profilePersonalSlice = createSlice({
  name: 'profilePersonal',
  initialState: {
    isShowEditModal: false,
    isNameWasEditSuccess: false,
    isEditActualRequest: false,
    isEditPersonal: false,
    errors: {
      firstName: '',
      lastName: '',
      actualRequest: '',
      bdate: '',
      city: '',
      about: '',
    },
  },
  reducers: {
    setIsShowEditModal: (state, { payload }: { payload: boolean }) => {
      state.isShowEditModal = payload;
    },
    setIsNameWasEditSuccess: (state, { payload }: { payload: boolean }) => {
      state.isNameWasEditSuccess = payload;
    },
    setIsEditActualRequest: (state, { payload }: { payload: boolean }) => {
      state.isEditActualRequest = payload;
    },
    setIsEditPersonal: (state, { payload }: { payload: boolean }) => {
      state.isEditPersonal = payload;
    },
    setErrors: (state, { payload }) => {
      state.errors = payload;
    },
    resetPersonal: (state) => {
      state.errors = {
        firstName: '',
        lastName: '',
        actualRequest: '',
        bdate: '',
        city: '',
        about: '',
      };
      state.isProjectEdit = false;
    },
  },
});

export const updatePersonalInfo = (data) => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.setLoading(true));

  try {
    const response = await axios.patch('/profile/personal', data);

    if (response?.status === 200 && response?.data) {
      dispatch(profileSlice.actions.setProfile(response.data));
      dispatch(commonSlice.actions.setLoading(false));

      if (data?.firstName || data?.lastName) {
        dispatch(profilePersonalSlice.actions.setIsShowEditModal(false));
        dispatch(profilePersonalSlice.actions.setIsNameWasEditSuccess(true));
      }

      dispatch(profilePersonalSlice.actions.setIsEditPersonal(false));

      dispatch(
        profilePersonalSlice.actions.setErrors({
          firstName: '',
          lastName: '',
          actualRequest: '',
          bdate: '',
          city: '',
        }),
      );
    }
  } catch (e) {
    if (e?.response.data.statusCode === 400) {
      dispatch(handleValidateErrors(e));
    }

    dispatch(commonSlice.actions.setLoading(false));
  }
};

const handleValidateErrors =
  (requestError) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const errors = requestError?.response?.data?.message;

    const filteredErrors = filterDeepErrorsByField(errors, PERSONAL_FIELD_ERRORS);

    if (filteredErrors.length > 0) {
      filteredErrors.forEach((err) => {
        dispatch(
          profilePersonalSlice.actions.setErrors({
            ...getState().personal.errors,
            ...err,
          }),
        );
      });
    }
  };

export default profilePersonalSlice;
