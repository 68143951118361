import { CommunityPage } from '@store/communityPage/types';

export interface BusinessCommunityState {
  isLoading: boolean;
  allCommunities: null;
  approvedCommunities: ProfileCommunity[];
  profileCommunities: ProfileCommunity[] | ChangeCommunity[] | null;
  businessCommunities: BusinessCommunity[] | null;
  clubCommunities: ProfileCommunity[];
  templateCommunities: null;
  removedCommunityIds: number[];
  communityErrors: Partial<CommunityErrors>;
  filteredCommunities: ProfileCommunity[];
}

export enum CommunityStatus {
  APPROVED = 'approved',
  ON_MODERATION = 'onModeration',
  REJECTED = 'rejected',
}

export enum CommunityType {
  CUSTOM = 'custom',
  CONNECTED = 'connected',
}

export interface ProfileCommunity {
  id: number;
  profileId: number;
  communityId: number;
  status: CommunityStatus;
  createdAt: string;
  updatedAt: string;
  communityType: CommunityType;
  title: string;
  url: string | null;
  isPublic: boolean;
  mainCommunityId?: number;
  page: CommunityPage;
}

export interface ChangeCommunity {
  id?: number;
  value?: number;
  name?: string;
  title: string;
  label?: string;
  url: string;
  __isNew__?: boolean;
}

export interface BusinessCommunity {
  communityType: 'connected';
  hasClub: boolean;
  id: number;
  title: string;
  url: string | null;
  isPublic: boolean;
  mainCommunityId?: number;
}

export interface FetchAllCommunitiesParams {
  page?: number;
  limit?: number;
  title?: string;
}

export interface CommunityErrors {
  default: string;
  emptyCustomName: string;
}

export interface CustomCommunity {
  title: string;
  url: string;
}

export interface CommunityFromRequest {
  id: number;
  profileId: number;
  communityId: number;
  status: CommunityStatus;
  createdAt: string;
  updatedAt: string;
}
