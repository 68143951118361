import { AxiosResponse } from 'axios';
import { Member, ProfileResponse } from './types';

export const filterMembersById = (members: Member[]) => {
  if (!members.length) return [];

  return members.filter((member) => member.profileId);
};

export const createMembers = (
  profileResponse: AxiosResponse<ProfileResponse, any>[],
  members: Member[],
): Member[] => {
  const profiles = profileResponse.map((profile) => ({
    profileId: profile.data.profile.id,
    company: !profile.data.profile.jobs.length
      ? ''
      : profile.data.profile.jobs.filter((job) => job.isMain).map((job) => job.place)[0],
    position: !profile.data.profile.jobs.length
      ? ''
      : profile.data.profile.jobs.filter((job) => job.isMain).map((job) => job.position)[0],
  }));

  return members.map((member) => {
    const profile = profiles.find((p) => p.profileId === member.profileId);
    return {
      ...member,
      ...profile,
    };
  });
};
