import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import axios from '@utils/axios';

export const CommunityInvitePage = () => {
  const params = useParams();

  const sendInviteRequest = async (communityId: string) => {
    await axios.get(`/club-invitation/${communityId}`);
  };

  useEffect(() => {
    if (!params.communityId) return;
    sendInviteRequest(params.communityId);
  }, [params.communityId]);

  return <Navigate to='/signup' />;
};
