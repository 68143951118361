import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios from '@utils/axios';

import { fetchUser } from '@store/auth/auth';

import { AppDispatch } from '@store/index';
import {
  SecurityState,
  ChangeProfilePhoneParams,
  SecurityErrors,
  VerifyChangingPhoneParams,
  PreviousEmailData,
  StartToChangeEmailParams,
  Account,
  SnackStatus,
  SnackIcon,
} from './types';

import { ACCOUNTS, SNACK_TEXT } from './constants';

export const securitySlice = createSlice({
  name: 'security',
  initialState: {
    isEditPhoneModal: false,
    isEditEmailModal: false,
    isShowChangeEmailModal: false,
    isNewEmailField: false,
    isNewPhoneField: false,
    isShowChangePhoneModal: false,
    isEditPhone: true,
    isEditEmail: false,
    isSuccess: false,
    templatePhone: '',
    securityErrors: {},
    previousEmail: {
      email: '',
      expiresAt: '',
    },
    isShowVerifyEmailSuccess: false,
    isFetching: false,
    isShowSnackbar: false,
    accountsWithStatus: [],
    snackStatus: {},
  } as SecurityState,
  reducers: {
    setIsEditPhoneModal(state, { payload }: { payload: boolean }) {
      state.isEditPhoneModal = payload;
    },
    setIsEditEmailModal(state, { payload }: { payload: boolean }) {
      state.isEditEmailModal = payload;
    },
    setIsShowChangeEmailModal(state, { payload }: { payload: boolean }) {
      state.isShowChangeEmailModal = payload;
    },
    setIsNewEmailField(state, { payload }: { payload: boolean }) {
      state.isNewEmailField = payload;
    },
    setIsNewPhoneField(state, { payload }: { payload: boolean }) {
      state.isNewPhoneField = payload;
    },
    setIsShowChangePhoneModal(state, { payload }: { payload: boolean }) {
      state.isShowChangePhoneModal = payload;
    },
    setIsEditPhone(state, { payload }: { payload: boolean }) {
      state.isEditPhone = payload;
    },
    setIsEditEmail(state, { payload }: { payload: boolean }) {
      state.isEditEmail = payload;
    },
    setIsSuccess(state, { payload }: { payload: boolean }) {
      state.isSuccess = payload;
    },
    setTemplatePhone(state, { payload }: { payload: string }) {
      state.templatePhone = payload;
    },
    setSecurityErrors(state, { payload }: { payload: Partial<SecurityErrors> }) {
      state.securityErrors = payload;
    },
    setPreviousEmailData: (state, { payload }: { payload: PreviousEmailData }) => {
      state.previousEmail = payload;
    },
    setIsShowVerifyEmailSuccess: (state, { payload }: { payload: boolean }) => {
      state.isShowVerifyEmailSuccess = payload;
    },
    setIsFetching: (state, { payload }: { payload: boolean }) => {
      state.isFetching = payload;
    },
    setIsShowSnackbar: (state, { payload }: { payload: boolean }) => {
      state.isShowSnackbar = payload;
    },
    setAccountsWithStatus: (state, { payload }: { payload: Account[] }) => {
      state.accountsWithStatus = payload;
    },
    setSnackStatus: (state, { payload }: { payload: SnackStatus }) => {
      state.snackStatus = payload;
    },
    reset: (state) => {
      state.isEditPhoneModal = false;
      state.isEditEmailModal = false;
      state.isShowChangeEmailModal = false;
      state.isNewEmailField = false;
      state.isNewPhoneField = false;
      state.isShowChangePhoneModal = false;
    },
  },
});

export const changeProfilePhone =
  (data: ChangeProfilePhoneParams) => async (dispatch: AppDispatch) => {
    dispatch(securitySlice.actions.setTemplatePhone(data.newPhone));
    dispatch(securitySlice.actions.setSecurityErrors({ phone: '' }));

    try {
      const response = await axios.post('/v2/auth/phone-change/start', data);

      if (response.status === 204) {
        dispatch(securitySlice.actions.setIsShowChangePhoneModal(true));
        dispatch(securitySlice.actions.setIsNewPhoneField(false));
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 409) {
        dispatch(securitySlice.actions.setSecurityErrors({ phone: 'Указан текущий номер' }));
      }
    }
  };

export const verifyChangingPhone =
  (data: VerifyChangingPhoneParams) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post('/v2/auth/phone-change/verify', data);

      if (response.status === 204) {
        dispatch(securitySlice.actions.setIsShowChangePhoneModal(false));
        dispatch(securitySlice.actions.setIsSuccess(true));
        dispatch(securitySlice.actions.setIsEditPhone(true));
        dispatch(securitySlice.actions.setIsEditEmail(false));
        dispatch(fetchUser());
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 409) {
        dispatch(
          securitySlice.actions.setSecurityErrors({
            phone: 'Этот номер телефона используется в другой учетной записи',
          }),
        );
      }
    }
  };

export const fetchPreviousChangingEmail = () => async (dispatch: AppDispatch) => {
  dispatch(securitySlice.actions.setIsFetching(true));

  try {
    const response = await axios.get('/v2/auth/email-change/pending');

    if (response.status === 200) {
      const expiresDate = Date.parse(response.data.expiresAt);
      const currentDate = new Date().getTime();
      const value = (expiresDate - currentDate) / 3600000;
      const hours = value > 0 ? Math.floor(value) : 0;

      dispatch(
        securitySlice.actions.setPreviousEmailData({
          email: response.data.email,
          expiresAt: String(hours),
        }),
      );
    }
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 404) {
      dispatch(securitySlice.actions.setSecurityErrors({ email: 'Нет запросов на смену почты' }));
    }
  } finally {
    dispatch(securitySlice.actions.setIsFetching(false));
  }
};

export const startToChangeEmail =
  ({ email, isChanging = false }: StartToChangeEmailParams) =>
  async (dispatch: AppDispatch) => {
    dispatch(securitySlice.actions.setIsFetching(true));

    try {
      const response = await axios.post('/v2/auth/email-change/start', { newEmail: email });

      if (response.status === 204) {
        if (isChanging) dispatch(fetchPreviousChangingEmail());
        dispatch(securitySlice.actions.setIsShowChangeEmailModal(true));
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        dispatch(securitySlice.actions.setSecurityErrors({ email: 'Введен некорректный email' }));
      } else if (error.response?.status === 409) {
        dispatch(securitySlice.actions.setSecurityErrors({ email: 'Эта почта уже установлена' }));
      }
    } finally {
      dispatch(securitySlice.actions.setIsFetching(false));
    }
  };

export const getAccountStatuses = () => async (dispatch: AppDispatch) => {
  const response = await axios.get('/oauth/status');

  if (response.status === 200) {
    const accounts: Account[] = ACCOUNTS.map((item) => ({
      ...item,
      isActive: response.data[item.name] || item.isActive,
    }));

    dispatch(securitySlice.actions.setAccountsWithStatus(accounts));
  }
};

export const unsubscribeAccount = (url: string) => async (dispatch: AppDispatch) => {
  const response = await axios.delete(url);

  if (response.status === 204) {
    dispatch(
      securitySlice.actions.setSnackStatus({
        icon: SnackIcon.done,
        text: SNACK_TEXT.UNSUBSCRIBE,
      }),
    );
    dispatch(securitySlice.actions.setIsShowSnackbar(true));
    dispatch(getAccountStatuses());
  }
};

export default securitySlice;
