// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';
import { AuthStateType, RemoveUserParams } from './types';

// TODO: типизировать

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    initDone: false,
    isImpersonate: false,
  } as AuthStateType,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.initDone = true;
    },
    setPicture: (state, action) => {
      state.user.picture = action.payload.normal;
      state.user.avatar = action.payload;
    },
    setImpersonate: (state, action) => {
      state.isImpersonate = action.payload;
    },
  },
});

export const fetchUser = () => async (dispatch: AppDispatch) => {
  const response = await axios.get('/v2/users/me');
  dispatch(authSlice.actions.setImpersonate(document.cookie.includes('IsImpersonateAsUser=true')));
  dispatch(authSlice.actions.setUser(response.data));
};

export const logout = () => {
  return async () => {
    await axios.post('/v2/auth/logout');
    window.open('/login', '_top');
  };
};

// @ts-ignore
window.logout = logout();

export const logoutRedesign = () => async () => {
  await axios.get('/auth/logout');
};

export const removeUser = (data: RemoveUserParams) => async (dispatch: AppDispatch) => {
  const response = await axios.delete('/v2/users/me', { params: data });

  if (response.status === 200) {
    dispatch(authSlice.actions.setUser(null));
  }
};

export const restoreUser = () => async (dispatch: AppDispatch) => {
  const response = await axios.post('/v2/users/me/restore/');

  if (response.status === 201) {
    dispatch(authSlice.actions.setUser(response.data.user));
  }
};

export default authSlice;
