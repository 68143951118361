import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';
import { CommunityTeamState, ResponseTeam, Team } from './types';

export const communityTeamSlice = createSlice({
  name: 'communityTeamStore',
  initialState: {
    team: [],
    isFetchingTeam: false,
  } as CommunityTeamState,
  reducers: {
    setIsFetchingTeam: (state, { payload }: { payload: boolean }) => {
      state.isFetchingTeam = payload;
    },
    setTeam: (state, { payload }) => {
      state.team = payload;
    },
    resetState: (state) => {
      state.team = [];
      state.isFetchingTeam = false;
    },
  },
});

export const fetchCommunityTeam = (communityId: number) => async (dispatch: AppDispatch) => {
  dispatch(communityTeamSlice.actions.setIsFetchingTeam(true));

  try {
    const response = await axios.get<ResponseTeam[]>(`/communities/pages/${communityId}/team`);

    if (response.data) {
      const team: Team[] = response.data.map((teamItem) => ({
        cardId: teamItem.cardId,
        description: teamItem.description,
        showTelegram: teamItem.showTelegram,
        name: `${teamItem.firstName} ${teamItem.lastName}`,
        telegram: teamItem.telegram,
        profileId: teamItem.profileId,
        avatar: teamItem.avatar,
      }));

      dispatch(communityTeamSlice.actions.setTeam(team));
    } else {
      dispatch(communityTeamSlice.actions.setTeam([]));
    }
  } catch (e) {
    console.log('Не удалось запросить команду');
  } finally {
    dispatch(communityTeamSlice.actions.setIsFetchingTeam(false));
  }
};

export default communityTeamSlice;
