export const PROJECTS_FIELD_ERRORS = {
  name: {
    minLength: 'Минимальное количество символов - 2',
    maxLength: 'Максимальное количество символов - 80',
  },
  description: {
    minLength: 'Минимальное количество символов - 15',
    maxLength: 'Максимальное количество символов - 280',
  },
  startYear: 'Необходимо указать год начала',
  link: 'Введена некорректная ссылка',
};
