export const randomUserIds = (userIds: number[]): number => {
  const randomIndex = Math.floor(Math.random() * userIds.length);
  return userIds[randomIndex];
};

export const formatVisitingCardField = (value: string[] | null) => {
  if (!value) return '';

  return value.toString().slice(1);
};
