import { FC, useMemo } from 'react';
import { clsx } from 'clsx';

import styles from './Logo.module.scss';

interface LogoProps {
  isRedesign?: boolean;
  logoClassname?: string;
  isMenu?: boolean;
  src?: string;
  isLanding?: boolean;
}

export const Logo: FC<LogoProps> = ({
  isRedesign,
  logoClassname,
  isMenu,
  src = '/images/logo/logo.svg',
  isLanding = false,
}) => {
  const isPWA = window.matchMedia('(display-mode: standalone)').matches;

  const link = useMemo(() => {
    if (isLanding) return '/';

    return isPWA ? '/login' : '/lead/services';
  }, [isLanding, isPWA]);

  return (
    <div
      className={clsx(styles.logo, logoClassname, {
        [styles.redesign]: isRedesign,
        [styles.menu]: isMenu,
      })}
    >
      <a href={link}>
        <img src={src} alt='Leaderport' />
      </a>
    </div>
  );
};
