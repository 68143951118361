import { ButtonProps, Tooltip } from '@mui/material';
import styles from './PaymentButton.module.scss';

type PaymentButtonProps = ButtonProps & {
  tooltip?: string;
};

export const PaymentButton = ({ children, tooltip, ...props }: PaymentButtonProps) => {
  return tooltip ? (
    <Tooltip title={tooltip}>
      <div>
        <button {...props} className={styles.button}>
          {children}
        </button>
      </div>
    </Tooltip>
  ) : (
    <button {...props} className={styles.button}>
      {children}
    </button>
  );
};
