export const formatRemovedDateTime = (currentTime: string) => {
  const originalDate = new Date(currentTime);
  const originalDatePlusOneMonth = new Date(originalDate.setMonth(originalDate.getMonth() + 1));

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  const formattedDate = originalDatePlusOneMonth.toLocaleDateString('ru-RU', options);
  const [date, time] = formattedDate.split('. ');
  const [onlyDate] = date.split('г');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, onlyTime] = time.split('в ');

  return `${onlyTime} ${onlyDate}`;
};
