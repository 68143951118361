export const JOBS_FIELD_ERRORS = {
  place: {
    minLength: 'Минимальное количество символов - 2',
    maxLength: 'Максимальное количество символов - 80',
  },
  position: {
    minLength: 'Минимальное количество символов - 2',
    maxLength: 'Максимальное количество символов - 80',
  },
};
