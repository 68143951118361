export interface RegistrationState {
  initialData: null;
  isSubmitting: boolean;
  redesignStep: number;
  step: number;
  isServices: boolean;
  isEditPhone: boolean;
  incorrectCode: boolean;
  isShowIncorrectTooltip: boolean;
  isManagerCallStep: boolean;
  isRegistrationSuccess: boolean;
  isRegisterLoading: boolean;
  registerPhone: string;
  isSms: boolean;
  registerError: Partial<RegisterError>;
  isRegisterWasSuccess: boolean;
  isStopRedirectStep: boolean;
  smsStepTitle: string;
  smsStepSubtitle: string;
  isSMS: boolean;
}

export interface RegisterPhone {
  phone: string;
  phoneChallengeType: RegisterPhoneType;
}

export enum RegisterPhoneType {
  CALL = 'call',
  SMS = 'sms',
}

export interface RegisterPersonalParams {
  firstName: string;
  lastName: string;
  actualRequest: string;
  bdate: string;
  about: string;
  city: string;
  visiting: string[];
  email: string;
}

export interface RegisterVerifyPhone {
  phone: string;
  code: string;
}

export interface RegisterError {
  invalidPhone: string;
  invalidEmail: string;
}

export type ContactsStepData = 'name' | 'lastname' | 'bdate' | 'city' | 'email';
