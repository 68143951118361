import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import urls from '@settings/urls.yaml';

import { fetchUser } from '@store/auth/auth';
import { fetchProfile, loadJobFields } from '@store/profile/profile';
import { loadSkills } from '@store/competence/competence';
import { getNotifications } from '@store/notification/notifications';
import { fetchProfileJobs } from '@store/profileJobs/profileJobs';
import { fetchProfileCommunities } from '@store/businessCommunity/businessCommunity';

import LoaderToggler from '@components/common/LoaderToggler';
import LogoutPage from '@components/landing/pages/LogoutPage/LogoutPage';
import Removed from '@components/removed/Removed';
import { InvitationPage } from '@components/main/pages/invitation/InvitationPage';
import { ErrorPage } from '@pages/errorPage/ErrorPage';
import { NetworkErrorPage } from '@pages/errorPage/NetworkErrorPage/NetworkErrorPage';

const Landing = lazy(() => import('./components/landing/Landing'));
const LandingPage = lazy(() => import('@pages/Landing/Landing'));
const MainRoutes = lazy(() => import('./components/main/MainRoutes'));
const RegisterPage = lazy(() => import('@pages/RegisterPage/RegisterPage'));
const ClubContacts = lazy(() => import('@pages/ClubContacts/ClubContacts'));
const Leaving = lazy(() => import('@pages/Leaving/Leaving'));

import { RootState, AppDispatch } from '@store/index';
import { fetchFilledVisitingCards } from '@store/services/services';
import { CommunityInvitePage } from '@pages/CommunityInvitePage/CommunityInvitePage';
import { PaymentStatus } from '@features/payments/pages/PaymentStatus/PaymentStatus';
import { PaySubscription } from '@features/payments/pages/PaySubscription/PaySubscription';
import { CommunityRedirection } from '@pages/CommunityRedirection/CommunityRedirection';

const AppRoutes = () => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const location = useLocation();

  const landing = (
    <Suspense fallback={<LoaderToggler />}>
      <Landing />
    </Suspense>
  );

  const register = (
    <Suspense fallback={<LoaderToggler />}>
      <RegisterPage />
    </Suspense>
  );

  const main = (
    <Suspense fallback={<LoaderToggler />}>
      <MainRoutes />
    </Suspense>
  );

  const removed = (
    <Suspense fallback={<LoaderToggler />}>
      <Removed />
    </Suspense>
  );

  const landingPage = (
    <Suspense fallback={<LoaderToggler />}>
      <LandingPage />
    </Suspense>
  );

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (user) {
        dispatch(getNotifications());
      }
    }, 15000);

    return () => clearInterval(timer);
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(getNotifications());
      dispatch(loadJobFields({}));
      dispatch(loadSkills());
      dispatch(fetchProfile());
      dispatch(fetchProfileJobs(user.id));
      dispatch(fetchProfileCommunities(user.id));
      dispatch(fetchFilledVisitingCards());
    }
  }, [user, dispatch, location.pathname]);

  if (!user && window.location.pathname.startsWith('/club-invite/')) {
    return (
      <Suspense fallback={<LoaderToggler />}>
        <Routes>
          <Route path='/club-contacts/' element={<ClubContacts />} />
        </Routes>
      </Suspense>
    );
  }

  if (!user && window.location.pathname.startsWith('/logout')) {
    return (
      <Suspense fallback={<LoaderToggler />}>
        <Routes>
          <Route path='/logout' element={<LogoutPage />} />
        </Routes>
      </Suspense>
    );
  }

  if (window.location.pathname.startsWith('/invitation/')) {
    return (
      <Routes>
        <Route path='/invitation/:userId' element={<InvitationPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path='community/:id' element={<CommunityRedirection />} />
      <Route path='pay/:paymentHash' element={<PaySubscription />} />
      <Route path='payments/status/:status' element={<PaymentStatus />} />
      <Route path={urls.landing.signout} element={<LogoutPage />} />
      <Route path='/invite/:communityId' element={<CommunityInvitePage />} />
      <Route path='/login' element={landing} />;
      <Route path={'/signup'} element={register} />
      <Route path={urls.removed.base} element={removed} />
      <Route path={`/lead/*`} element={main} />
      <Route index path='/*' element={landingPage} />
      <Route path='/invitation/:userId' element={<InvitationPage />} />
      <Route path='/error' element={<ErrorPage />} />
      <Route path='/network' element={<NetworkErrorPage />} />
      <Route path='/club-contacts/' element={<ClubContacts />} />
      <Route
        path='/leaving'
        element={
          <Suspense fallback={<LoaderToggler />}>
            <Leaving />
          </Suspense>
        }
      />
      ;
    </Routes>
  );
};

export default AppRoutes;
