export const PAYMENT_METHODS = [
  {
    name: 'card',
    title: 'Банковская карта',
    iconImage: '/images/payments/card.webp',
    label: 'картой',
  },
  {
    name: 'tpay',
    title: 'T-Pay',
    iconImage: '/images/payments/t-pay.webp',
    label: 'через T-Pay',
  },
  {
    name: 'sberpay',
    title: 'SberPay',
    iconImage: '/images/payments/sberpay.webp',
    label: 'через SberPay',
  },
  {
    name: 'mirpay',
    title: 'MIR Pay',
    iconImage: '/images/payments/mirpay.webp',
    label: 'через MIR Pay',
  },
  {
    name: 'sbp',
    title: 'Система быстрых платежей (QR код)',
    iconImage: '/images/payments/sbp.webp',
    label: 'через СБП',
  },
];
