// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { fetchProfile } from '../profile/profile';

import { AppDispatch } from '@store/index';

// TODO: типизировать

export const profileUISlice = createSlice({
  name: 'profileUI',
  initialState: { karmaDescription: false },
  reducers: {
    setIsShowKarmaDescription: (state, { payload }) => {
      state.karmaDescription = payload;
    },
  },
});

export const updateKarmaDescription = (data) => async (dispatch: AppDispatch) => {
  const response = await axios.put('/profile/update-ui-state/', { karmaDescription: data });
  dispatch(profileUISlice.actions.setIsShowKarmaDescription(response.data));
  dispatch(fetchProfile());
};

export default profileUISlice;
