// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { getNotifications } from '../notification/notifications';

import { AppDispatch } from '@store/index';

// TODO: типизировать

export const clubSlice = createSlice({
  name: 'club',
  initialState: {
    savedFilters: null,
    learnStep: null,
    isShowSuccessRequestModal: false,
    isShowSecurityModal: false,
    isShowSecurityDemoModal: false,
    isShowCommunityModal: false,
    activeCommunityId: null,
    actionUrl: '',
    userCount: {
      register: 0,
      unregister: 0,
      all: 0,
    },
    filters: {
      cities: [],
      fields: [],
      competences: [],
    },
  },
  reducers: {
    setIsShowSecurityModal: (state, { payload }) => {
      state.isShowSecurityModal = payload;
    },
    setIsShowSecurityDemoModal: (state, { payload }) => {
      state.isShowSecurityDemoModal = payload;
    },
    setIsShowCommunityModal: (state, { payload }) => {
      state.isShowCommunityModal = payload;
    },
    setActiveCommunityId: (state, { payload }) => {
      state.activeCommunityId = payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setIsShowSuccessRequestModal: (state, { payload }) => {
      state.isShowSuccessRequestModal = payload;
    },
    setUserCount: (state, { payload }) => {
      state.userCount = payload;
    },
    reset: (state) => {
      state.isShowSecurityModal = false;
      state.isShowSecurityDemoModal = false;
      state.isShowCommunityModal = false;
      state.actionUrl = '';
    },
  },
});

export const fetchFilters = (comId: string | null) => async (dispatch: AppDispatch) => {
  if (comId) {
    const response = await axios.get('/club/filters/', {
      params: {
        comId,
      },
    });

    const fields = response.data.filters.fields;
    const otherFields = response.data.filters.otherFields;
    const filters = response.data.filters;

    const correctFilters = {
      ...filters,
      fields: [...fields, ...otherFields],
      otherFields: [],
    };

    dispatch(clubSlice.actions.setFilters(correctFilters));
  }
};

export const requestContacts = (data) => async () => {
  await axios.post('/club/request-contacts/', {
    requests: data,
  });
};

export const acceptContact = (acceptURL: string) => async (dispatch: AppDispatch) => {
  await axios.patch(`${acceptURL}`);
  await dispatch(getNotifications());
};

export const declineContact = (declineURL: string) => async (dispatch: AppDispatch) => {
  await axios.patch(`${declineURL}`);
  await dispatch(getNotifications());
};

export default clubSlice;
