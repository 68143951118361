import { FC, ButtonHTMLAttributes } from 'react';
import { clsx } from 'clsx';

import styles from './Button.module.scss';

import { ButtonSize, ButtonColor } from './types';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  customClassName?: string;
  secondary?: boolean;
  size?: ButtonSize;
  color?: ButtonColor;
}

const Button: FC<Props> = ({
  children,
  customClassName,
  secondary = false,
  size,
  color,
  ...props
}) => {
  return (
    <button
      {...props}
      className={clsx(styles.button, customClassName, {
        [styles.button_secondary]: secondary,
        [styles[`button_${size}`]]: size,
        [styles[`button_${color}`]]: color,
      })}
    >
      {children}
    </button>
  );
};

export default Button;
