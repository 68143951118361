import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchUser } from '@store/auth/auth';

import { AppDispatch, RootState } from '@store/index'

export const useUsers = () => {
  const dispatch: AppDispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!user) dispatch(fetchUser());
  }, [dispatch, user]);

  return user;
};
