import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';
import { ResourceCardState, ServiceBetaTest } from './types';

const resourceCardSlice = createSlice({
  name: 'resourceCard',
  initialState: {
    isShowBetaTest: false,
    isSuccessInvite: false,
  } as ResourceCardState,
  reducers: {
    setIsShowBetaTest: (state, { payload }: { payload: boolean }) => {
      state.isShowBetaTest = payload;
    },
    setIsSuccessInvite: (state, { payload }: { payload: boolean }) => {
      state.isSuccessInvite = payload;
    },
  },
});

export const inviteToBetaTester = (service: ServiceBetaTest) => async (dispatch: AppDispatch) => {
  const response = await axios.post('/feedback/beta', { service });

  if (response.status === 201) {
    dispatch(resourceCardSlice.actions.setIsSuccessInvite(true));
    dispatch(resourceCardSlice.actions.setIsShowBetaTest(false));
    localStorage.setItem('inviteBetaClub', 'true');
  }
};

export default resourceCardSlice;
