interface Fields {
  [key: string]: string;
}

export const FIELDS: Fields = {
  COMMUNITY: 'community',
  POSITION: 'position',
  NAME: 'name',
  LASTNAME: 'lastname',
  PHONE: 'phone',
  DESCRIPTION: 'description',
  EMAIL: 'email',
  TELEGRAM: 'telegram',
  RESIDENTS_COUNT: 'residentsCount',
  WEB_SITE: 'webSite',
  BDATE: 'bdate',
  CITY: 'city',
  PROJECT_NAME: 'projectName',
  RESOURCE_MODAL_TITLE: 'resourceModalTitle',
};
