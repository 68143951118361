import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios from '@utils/axios';

import { stopLoading } from '../common/common';

import profileSlice from '../profile/profile';
import { fetchUser } from '../auth/auth';

import { AppDispatch, RootState } from '@store/index';
import {
  RegisterError,
  RegisterPersonalParams,
  RegisterPhone,
  RegisterPhoneType,
  RegisterVerifyPhone,
  RegistrationState,
} from './types';

import { SMS_STEP_TITLE, SMS_STEP_SUBTITLE } from './constants';

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    initialData: null,
    isSubmitting: false,
    redesignStep: 1,
    step: 1,
    isServices: false,
    isEditPhone: false,
    incorrectCode: false,
    isShowIncorrectTooltip: false,
    isManagerCallStep: false,
    isRegistrationSuccess: false,
    isRegisterLoading: false,
    registerPhone: '',
    isSms: false,
    registerError: {},
    isRegisterWasSuccess: false,
    isStopRedirectStep: false,
    smsStepTitle: SMS_STEP_TITLE.APPROVE,
    smsStepSubtitle: SMS_STEP_SUBTITLE.CALL,
    isSMS: false,
  } as RegistrationState,
  reducers: {
    setSubmitting: (state, { payload }: { payload: boolean }) => {
      state.isSubmitting = payload;
    },
    changeRedesignStep: (state, { payload }: { payload: number }) => {
      state.redesignStep = payload;
    },
    nextStep: (state) => {
      let newStep = state.step + 1;
      if (newStep > 5) newStep = 5;
      state.step = newStep;
    },
    prevStep: (state) => {
      let newStep = state.step - 1;
      if (newStep < 1) newStep = 1;
      state.step = newStep;
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    reset: (state) => {
      state.initialData = null;
      state.isSubmitting = false;
      state.step = 1;
    },
    setIsServices: (state, { payload }: { payload: boolean }) => {
      state.isServices = payload;
    },
    setIsEditPhone: (state, { payload }: { payload: boolean }) => {
      state.isEditPhone = payload;
    },
    setIsIncorrectCode: (state, { payload }: { payload: boolean }) => {
      state.incorrectCode = payload;
    },
    setIsShowIncorrectTooltip: (state, { payload }: { payload: boolean }) => {
      state.isShowIncorrectTooltip = payload;
    },
    setIsManagerCallStep: (state, { payload }: { payload: boolean }) => {
      state.isManagerCallStep = payload;
    },
    setIsRegistrationSuccess: (state, { payload }: { payload: boolean }) => {
      state.isRegistrationSuccess = payload;
    },
    setIsRegisterLoading: (state, { payload }: { payload: boolean }) => {
      state.isRegisterLoading = payload;
    },
    setRegisterPhone: (state, { payload }: { payload: string }) => {
      state.registerPhone = payload;
    },
    setIsSms: (state, { payload }: { payload: boolean }) => {
      state.isSms = payload;
    },
    setRegisterError: (state, { payload }: { payload: Partial<RegisterError> }) => {
      state.registerError = payload;
    },
    setIsRegisterWasSuccess: (state, { payload }: { payload: boolean }) => {
      state.isRegisterWasSuccess = payload;
    },
    setIsStopRedirectStep: (state, { payload }: { payload: boolean }) => {
      state.isStopRedirectStep = payload;
    },
    setSmsStepTitle: (state, { payload }: { payload: string }) => {
      state.smsStepTitle = payload;
    },
    setSmsStepSubtitle: (state, { payload }: { payload: string }) => {
      state.smsStepSubtitle = payload;
    },
    setIsSMS: (state, { payload }: { payload: boolean }) => {
      state.isSMS = payload;
    },
  },
});

export const startRegister =
  (data: RegisterPhone, isLogin = false) =>
  async (dispatch: AppDispatch) => {
    dispatch(registrationSlice.actions.setIsRegisterLoading(true));
    dispatch(registrationSlice.actions.setRegisterPhone(data.phone));

    try {
      const response = await axios.post('/v2/auth/entry/start', data);

      if (response.status === 204) {
        isLogin
          ? dispatch(registrationSlice.actions.setIsSms(true))
          : dispatch(registrationSlice.actions.changeRedesignStep(2));
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        dispatch(
          registrationSlice.actions.setRegisterError({
            invalidPhone: 'Введен некорректный номер телефона',
          }),
        );
      }

      if (error.response?.status === 500 && data.phoneChallengeType === RegisterPhoneType.CALL) {
        dispatch(
          startRegister({ phone: data.phone, phoneChallengeType: RegisterPhoneType.SMS }, isLogin),
        );
        dispatch(registrationSlice.actions.setSmsStepTitle(SMS_STEP_TITLE.CODE));
        dispatch(registrationSlice.actions.setSmsStepSubtitle(SMS_STEP_SUBTITLE.SEND));
        dispatch(registrationSlice.actions.setIsSMS(true));
      }
    } finally {
      dispatch(registrationSlice.actions.setIsRegisterLoading(false));
    }
  };

export const registerVerifyPhone =
  (data: RegisterVerifyPhone, isLogin: boolean = false, isTablet: boolean) =>
  async (dispatch: AppDispatch) => {
    dispatch(registrationSlice.actions.setIsRegisterLoading(true));

    try {
      const response = await axios.post('/v2/auth/entry/verify', data);

      if (response.status === 204) {
        dispatch(fetchUser());

        if (!isLogin) {
          isTablet
            ? dispatch(registrationSlice.actions.changeRedesignStep(0))
            : dispatch(registrationSlice.actions.changeRedesignStep(3));
        }
      }
    } catch (e) {
      dispatch(registrationSlice.actions.setIsIncorrectCode(true));
      dispatch(registrationSlice.actions.setIsShowIncorrectTooltip(true));
    } finally {
      dispatch(registrationSlice.actions.setIsRegisterLoading(false));
    }
  };

export const competeRegistration = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post('/v2/auth/signup/complete');

    if (response.status === 204) {
      dispatch(registrationSlice.actions.setIsRegisterWasSuccess(true));
      dispatch(fetchUser());
    }
  } catch (e) {
    // TODO: написать эксепшены
  }
};

export const completeRegistrationPersonal =
  (data: Partial<RegisterPersonalParams>) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const response = await axios.patch('/profile/personal', {
        firstName: data.firstName?.trim(),
        lastName: data.lastName?.trim(),
        bdate: data.bdate?.trim(),
        city: data.city?.trim(),
      });

      if (response.status === 200) {
        const oldEmail = getState().auth.user?.email;

        data.email && oldEmail !== data.email
          ? dispatch(changeProfileEmail(data.email))
          : dispatch(registrationSlice.actions.changeRedesignStep(4));

        dispatch(profileSlice.actions.setProfile(response.data.profile));
      }
    } catch (e) {
      throw new Error(`save personal: ${e}`);
    } finally {
      dispatch(stopLoading());
    }
  };

export const changeProfileEmail = (email: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post('/v2/auth/email-change/start', { newEmail: email.trim() });

    if (response.status === 204) {
      dispatch(registrationSlice.actions.changeRedesignStep(4));
    }
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 400) {
      dispatch(
        registrationSlice.actions.setRegisterError({
          invalidEmail: 'Введен некорректный email',
        }),
      );
    } else if (error.response?.status === 409) {
      dispatch(
        registrationSlice.actions.setRegisterError({
          invalidEmail: 'Эта почта уже используется',
        }),
      );
    }
  }
};

export const completeDateTimeManagerCall =
  (dateTimeAndMethod: unknown, isProfile: boolean) => async (dispatch: AppDispatch) => {
    const response = await axios.post('/profile/registration/verify-date-time', dateTimeAndMethod);
    if (response.data.success) {
      dispatch(profileSlice.actions.setProfile(response.data.profile));
      if (!isProfile) {
        window.open('/lead/account', '_self');
      }
    }
  };

export default registrationSlice;
