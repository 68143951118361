import { createSlice } from '@reduxjs/toolkit';

import { CommonState } from './types';
import { AppDispatch } from 'store/index';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isGlobalLoading: true,
    loading: false,
    showInvitation: false,
    isShowMobileMenu: true,
    isExpendedMobileMenu: false,
  } as CommonState,
  reducers: {
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setGlobalLoading: (state, { payload }: { payload: boolean }) => {
      state.isGlobalLoading = payload;
    },
    showInvitation: (state) => {
      state.showInvitation = true;
    },
    hideInvitation: (state) => {
      state.showInvitation = false;
    },
    setShowMobileMenu: (state, { payload }: { payload: boolean }) => {
      state.isShowMobileMenu = payload;
    },
    setIsExpendedMobileMenu: (state, { payload }: { payload: boolean }) => {
      state.isExpendedMobileMenu = payload;
    },
  },
});

export const stopLoading = () => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.setLoading(false));
};

export const updateGlobalLoading = (value: boolean) => async (dispatch: AppDispatch) => {
  await dispatch(commonSlice.actions.setGlobalLoading(value));
};

export default commonSlice;
