// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

import axios from '@utils/axios';

import { fetchProfile, fetchRequests } from '../profile/profile';

import { AppDispatch } from '@store/index';

// TODO: типизировать

export const systemSlice = createSlice({
  name: 'system',
  initialState: {
    count: 0,
    step: -1,
    isFullSizeMenu: true,
  },
  reducers: {
    setCount: (state, { payload }) => {
      state.count = payload;
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    setIsFullSizeMenu: (state, { payload }) => {
      state.isFullSizeMenu = payload;
    },
  },
});

export const fetchRequestsCount = () => async (dispatch: AppDispatch) => {
  const response = await axios.get('/system/requests-count/');
  dispatch(systemSlice.actions.setCount(+response.data));
};

export const sendSystemRequest = (data) => async (dispatch: AppDispatch) => {
  const response = await axios.post('/system/request/', data);
  dispatch(systemSlice.actions.setCount(+response.data.count));
  dispatch(fetchProfile());
  dispatch(systemSlice.actions.setStep(1));
  dispatch(fetchRequests());
};

export default systemSlice;
