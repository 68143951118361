import { ProfileCommunity } from '@store/businessCommunity/types';
import { MenuType } from '@features/Menu/types';
import { validateResourceBeforeSubmitParams, Resource } from './types';

import { FIELDS } from '@utils/constants';

export const validateResourceBeforeSubmit = ({
  resource,
  setErrors,
}: validateResourceBeforeSubmitParams): boolean => {
  let hasError = false;

  if (!resource.title) {
    setErrors({
      field: 'title',
      value: 'Поле обязательно для заполнения',
    });
    hasError = true;
  }

  if (resource.title && (resource.title.length < 2 || resource.title.length > 80)) {
    setErrors({
      field: 'title',
      value: 'от 2 до 80 символов',
    });
    hasError = true;
  }

  if (
    resource.description &&
    (resource.description.length < 15 || resource.description.length > 280)
  ) {
    setErrors({
      field: FIELDS.DESCRIPTION,
      value: 'от 2 до 80 символов',
    });
    hasError = true;
  }

  if (resource.tags?.length > 20) {
    setErrors({
      field: 'tags',
      value: 'Вы можете добавить максимум 20 тегов',
    });
    hasError = true;
  }

  if (!resource.links.length) {
    setErrors({
      field: 'resourceLinks',
      value: 'Вы не можете создать блок не прикрепив материалы',
    });
    hasError = true;
  }

  return hasError;
};

export const isFormWasEdit = (resource: Resource): boolean => {
  if (resource.title) return true;

  if (resource.description) return true;

  if (resource.tags.length > 0) return true;

  return resource.links.length > 0;
};

export const transformCommunitiesForMenu = (
  approvedCommunities: ProfileCommunity[],
): MenuType[] => {
  return approvedCommunities.map((community) => ({
    id: community.communityId,
    iconName: '',
    title: community.title,
    hasNotification: false,
    link: `/lead/community/${community.communityId}`,
    isBig: false,
    isCommunity: true,
    isPublic: community.isPublic,
    mainCommunityId: community.mainCommunityId,
    page: community.page,
  }));
};
