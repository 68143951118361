import { RegisterPhoneType } from '@store/registration/types';

export interface SecurityState {
  isEditPhoneModal: boolean;
  isEditEmailModal: boolean;
  isShowChangeEmailModal: boolean;
  isNewEmailField: boolean;
  isNewPhoneField: boolean;
  isShowChangePhoneModal: boolean;
  isEditPhone: boolean;
  isEditEmail: boolean;
  isSuccess: boolean;
  templatePhone: string;
  securityErrors: Partial<SecurityErrors>;
  previousEmail: PreviousEmailData;
  isShowVerifyEmailSuccess: boolean;
  isFetching: boolean;
  isShowSnackbar: boolean;
  accountsWithStatus: Account[];
  snackStatus: Partial<SnackStatus>;
}

export interface ChangeProfilePhoneParams {
  newPhone: string;
  phoneChallengeType: RegisterPhoneType;
}

export interface SecurityErrors {
  phone: string;
  email: string;
}

export interface VerifyChangingPhoneParams {
  newPhone: string;
  code: string;
}

export interface PreviousEmailData {
  email: string;
  expiresAt: string;
}

export interface StartToChangeEmailParams {
  email: string;
  isChanging?: boolean;
}

export interface Account {
  title: string;
  icon: string;
  isActive: boolean;
  name: string;
  url: string;
  unsubscribeUrl: string;
  loginUrl: string;
}

export interface SnackStatus {
  icon: SnackIcon;
  text: string;
}

export enum SnackIcon {
  error = 'error',
  done = 'done',
}
