import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { Button } from '@components/core-ui/Button/Button';
import { Modal } from '@components/core-ui/Modal/Modal';

import styles from './NetworkErrorPage.module.scss';

export const NetworkErrorPage = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/lead/account');
  };

  return (
    <Modal
      containerClassName={styles.container}
      contentClassName={styles.body}
      closeIconClassName={styles.close}
      backdropClassName={styles.backdrop}
      onClose={handleClose}
    >
      <div className={styles.content}>
        <span className={styles.title}> Нет соединения с интернетом</span>
        <div className={styles.frame}>
          <div className={styles.textAndImg}>
            <div className={styles.textAndImg_text}>
              {isMobile ? (
                <span>
                  Не волнуйтесь, данные заявки <br /> не пропадут
                </span>
              ) : (
                <span>Не волнуйтесь, данные заявки не пропадут</span>
              )}
            </div>
            <img src='/images/gif/squirrel.gif' alt='' />
          </div>

          <Button className={styles.button} onClick={handleClose}>
            Попробовать еще раз
          </Button>
        </div>
      </div>
    </Modal>
  );
};
