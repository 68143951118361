import { formatPrice } from '@utils/string';
import styles from './SubscriptionDescription.module.scss';
import { SubscriptionType } from '@features/payments/types/subscription.type';
import { OrderType } from '@features/payments/types/order.type';
import { plural } from '@utils/string';
import { formatPhoneByMask } from '@utils/formatPhoneByMask';
import { useMediaQuery } from '@mui/material';

type SubscriptionDescriptionProps = {
  subscription: SubscriptionType;
  order: OrderType;
  phone: string;
};

export const SubscriptionDescription = ({
  subscription,
  order,
  phone,
}: SubscriptionDescriptionProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return isMobile ? (
    <div className={styles.subscriptionMobile}>
      <div className={styles.description}>
        <p>
          Подписка на сообщество <strong>«{subscription.community.title}»</strong> на{' '}
          <strong>
            {order.period} {plural(order.period, 'месяц', 'месяца', 'месяцев')}
          </strong>
          .
        </p>
        <p>
          Номер телефона: <strong>{formatPhoneByMask(phone)}</strong>.
        </p>
      </div>
      <div className={styles.price}>{formatPrice(order.amount)} ₽</div>
    </div>
  ) : (
    <div className={styles.subscription}>
      <div className={styles.description}>
        <p>
          Подписка на сообщество <strong>«{subscription.community.title}»</strong> на{' '}
          <strong>
            {order.period} {plural(order.period, 'месяц', 'месяца', 'месяцев')}
          </strong>
          .
        </p>
        <p>
          Номер телефона: <strong>{formatPhoneByMask(phone)}</strong>.
        </p>
      </div>
      <div className={styles.price}>{formatPrice(order.amount)} ₽</div>
    </div>
  );
};
