import { Account } from './types';

import { icons } from '@icons/icons';

const URL = import.meta.env.VITE_REACT_APP_API_URL;

export const ACCOUNTS: Account[] = [
  {
    title: 'VK',
    icon: icons.vk,
    isActive: false,
    name: 'vk',
    url: `${URL}oauth/vk/link/redirect`,
    loginUrl: `${URL}oauth/vk/login/redirect`,
    unsubscribeUrl: `/oauth/vk/link`,
  },
  {
    title: 'Яндекс',
    icon: icons.yandex,
    isActive: false,
    name: 'yandex',
    url: `${URL}oauth/yandex/link/redirect`,
    loginUrl: `${URL}oauth/yandex/login/redirect`,
    unsubscribeUrl: `/oauth/yandex/link`,
  },
  {
    title: 'Mail ru',
    icon: icons.mailru,
    isActive: false,
    name: 'mail-ru',
    url: `${URL}oauth/mail-ru/link/redirect`,
    loginUrl: `${URL}oauth/mail-ru/login/redirect`,
    unsubscribeUrl: `/oauth/mail-ru/link`,
  },
  {
    title: 'Leader-ID',
    icon: icons.leader,
    isActive: false,
    name: 'leader-id',
    url: `${URL}oauth/leader-id/link/redirect`,
    loginUrl: `${URL}oauth/leader-id/login/redirect`,
    unsubscribeUrl: `/oauth/leader-id/link`,
  },
  // {
  //   title: 'Госуслуги',
  //   icon: icons.gu,
  //   isActive: false,
  //   name: 'gu',
  //   url: '',
  //   unsubscribeUrl: ``,
  // },
];

export const SNACK_TEXT = {
  SUBSCRIBE: 'Аккаунт успешно привязан',
  UNSUBSCRIBE: 'Аккаунт успешно отвязан',
  ALREADY_EXIST: 'Этот аккаунт уже привязан к другой учётной записи на платформе',
  NO_ACCOUNT: 'Этот аккаунт не связан ни с одной учетной записью на платформе',
};
