import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { formatCalendars, createCalendarEvents, createCalendarEvent, findCalendar } from './utils';

import { AppDispatch, RootState } from '@store/index';
import { Tab } from '@commonTypes/type';
import {
  Calendar,
  CalendarState,
  FetchCalendarListResponse,
  CalendarEventResponse,
  CalendarEvent,
  RegisterData,
  FetchEventResponse,
  SearchEventsPayload,
  SearchEventsResponse,
} from './types';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    calendarTabs: [],
    activeTab: '',
    calendars: [],
    events: [],
    calendarEvent: {
      eventId: 0,
      title: '',
      date: '',
      isOnline: false,
      city: '',
      startTime: 0,
      finish: '',
      rrule: '',
      hash: '',
      recurring: '',
      description: '',
      link: '',
      type: '',
      area: '',
      place: '',
      image: '',
      sponsor: '',
      price: '',
    },
    isShowCalendarEventCard: false,
    isShowRegisterModal: false,
    isRegisterEvent: false,
    isSuccessRegisterEvent: false,
    isEmptyCalendar: false,
    isLoading: true,
    isOpenEventOnClick: false,
    currentCalendarId: null,
    isShowEventPicture: false,
  } as CalendarState,
  reducers: {
    setActiveTab: (state, { payload }: { payload: string }) => {
      state.activeTab = payload;
    },
    setCalendars: (state, { payload }: { payload: Calendar[] }) => {
      state.calendars = payload;
    },
    setTabsAndActiveTab: (state, { payload }: { payload: Tab[] }) => {
      if (payload.length) {
        state.calendarTabs = payload;
        state.activeTab = payload[0].name;
      } else {
        state.calendarTabs = [];
      }
    },
    setCalendarEvents: (state, { payload }: { payload: CalendarEvent[] }) => {
      state.events = payload;
    },
    setCalendarEvent: (state, { payload }: { payload: CalendarEvent }) => {
      state.calendarEvent = payload;
      state.isShowCalendarEventCard = true;
    },
    setIsShowCalendarEventCard: (state, action) => {
      state.isShowCalendarEventCard = action.payload;
    },
    setIsShowRegisterModal: (state, { payload }: { payload: boolean }) => {
      state.isShowRegisterModal = payload;
    },
    setIsRegisterEvent: (state, { payload }) => {
      state.isRegisterEvent = payload;
    },
    setIsSuccessRegisterEvent: (state, { payload }) => {
      state.isSuccessRegisterEvent = payload;
    },
    setIsEmptyCalendar: (state, { payload }: { payload: boolean }) => {
      state.isEmptyCalendar = payload;
    },
    setIsLoading: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
    setIsOpenEventOnClick: (state, { payload }: { payload: boolean }) => {
      state.isOpenEventOnClick = payload;
    },
    setCurrentCalendarId: (state, { payload }: { payload: number | null }) => {
      state.currentCalendarId = payload;
    },
    setIsShowEventPicture: (state, { payload }: { payload: boolean }) => {
      state.isShowEventPicture = payload;
    },
  },
});

export const fetchCalendarList = () => async (dispatch: AppDispatch) => {
  const response = await axios.get<FetchCalendarListResponse>(`/calendar/`);

  if (response.data.calendars.length) {
    dispatch(calendarSlice.actions.setCalendars(formatCalendars(response.data.calendars)));
  }
};

export const fetchCalendarEvents =
  (communityTitle: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(calendarSlice.actions.setIsLoading(true));

    const calendars = getState().calendar.calendars;
    const currentCalendar = findCalendar({ calendars, communityTitle });

    if (!currentCalendar) {
      dispatch(calendarSlice.actions.setIsEmptyCalendar(true));
      dispatch(calendarSlice.actions.setIsLoading(false));
      dispatch(calendarSlice.actions.setCurrentCalendarId(null));
      return null;
    }

    try {
      const response = await axios.get<CalendarEventResponse>(
        `/calendar/${currentCalendar.calendarId}/events`,
      );

      dispatch(calendarSlice.actions.setCurrentCalendarId(currentCalendar.calendarId));

      if (response.data.events.length) {
        const sortedEvents: CalendarEvent[] = createCalendarEvents(response.data.events);

        if (!sortedEvents.length) {
          dispatch(calendarSlice.actions.setIsEmptyCalendar(true));
          return;
        }

        dispatch(calendarSlice.actions.setCalendarEvents(sortedEvents));
        dispatch(calendarSlice.actions.setIsEmptyCalendar(false));
      }
    } catch (e) {
      dispatch(calendarSlice.actions.setIsEmptyCalendar(true));
    } finally {
      dispatch(calendarSlice.actions.setIsLoading(false));
    }
  };

export const fetchEvent =
  (eventId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const response = await axios.get<FetchEventResponse>(`/calendar/events/${eventId}`);

      if (response.data) {
        const userId = getState().auth.user?.id;

        if (userId) {
          const { event, registration } = response.data;
          const wasRegister = registration && registration.userId === userId;

          const calendarEvent: CalendarEvent = createCalendarEvent({ event, wasRegister });

          const currentCalendarId = getState().calendar.currentCalendarId;

          if (currentCalendarId && window.location.pathname.includes('/lead/services/calendar')) {
            window.history.replaceState(
              {},
              '/lead/services/calendar',
              `/lead/services/calendar/${currentCalendarId}/events/${eventId}`,
            );
          }

          dispatch(calendarSlice.actions.setIsOpenEventOnClick(true));
          dispatch(calendarSlice.actions.setCalendarEvent(calendarEvent));
        }
      }
    } catch (e) {
      console.log('Не удалось запросить событие');
    }
  };

export const searchEvents =
  ({ search, communityTitle }: SearchEventsPayload) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    let params = {};

    if (search) {
      params = {
        search,
      };
    }

    const calendars = getState().calendar.calendars;
    const currentCalendar = findCalendar({ calendars, communityTitle });

    if (!currentCalendar) return null;

    const response = await axios.get<SearchEventsResponse>(`/calendar/events`, { params });

    if (response.data.data.length) {
      const filteredEventsByCalendarId = response.data.data.filter((event) =>
        event.calendarsIds.includes(String(currentCalendar.calendarId)),
      );

      const sortedEvents: CalendarEvent[] = createCalendarEvents(filteredEventsByCalendarId);

      dispatch(calendarSlice.actions.setCalendarEvents(sortedEvents));
      dispatch(calendarSlice.actions.setIsEmptyCalendar(false));
    }
  };

export const registrationToEvent = (data: RegisterData) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post('/calendar/registration', data);

    if (response.status === 201) {
      dispatch(calendarSlice.actions.setIsShowRegisterModal(false));
      dispatch(calendarSlice.actions.setIsSuccessRegisterEvent(true));
    }
  } catch (e) {
    console.log(e);
  }
};

export default calendarSlice;
