// @ts-nocheck
import { contains } from '@utils/arr';

export const specialistLevelItems = [
  { title: 'топ-менеджер', id: 'topManager' },
  { title: 'проектный персонал', id: 'projectStaff' },
  { title: 'линейный руководитель', id: 'lineManager' },
  { title: 'квалифицированный специалист, менеджер', id: 'expert' },
  { title: 'рабочий персонал', id: 'operatingPersonnel' },
  { title: 'начальник отдела', id: 'departmentHead' },
];

export function getSpecialistLevelNameById(id) {
  return specialistLevelItems.find((item) => item.id === id).title;
}

export const filterOptionsItems = {
  industry: 'industry',
  specialistLevel: 'specialist level',
};

export const searchSelectItemsId = {
  vacancy: 'vacancy',
  user: 'user',
};

export const searchSelectItems = [
  { title: 'По вакансиям', id: searchSelectItemsId.vacancy },
  { title: 'По пользователям', id: searchSelectItemsId.user },
];

export function filterVacancies(vacancies, filterValue, searchString, searchSelectItemId) {
  let vacanciesCopy = [...vacancies];

  if (filterValue[filterOptionsItems.specialistLevel].length) {
    vacanciesCopy = vacanciesCopy.filter((vacancy) =>
      filterValue[filterOptionsItems.specialistLevel].includes(vacancy.vacancyLevel),
    );
  }

  if (filterValue[filterOptionsItems.industry].length) {
    vacanciesCopy = vacanciesCopy.filter((vacancy) => {
      return contains(
        filterValue[filterOptionsItems.industry],
        vacancy.fields.map((tag) => tag.id),
      );
    });
  }

  if (searchString) {
    return [...vacanciesCopy].filter((item) => {
      if (searchSelectItemId === searchSelectItemsId.vacancy) {
        return item?.vacancyTitle.toLowerCase().includes(searchString.toLowerCase());
      } else if (searchSelectItemId === searchSelectItemsId.user) {
        return item?.ownerName?.toLowerCase().includes(searchString.toLowerCase());
      }
    });
  } else {
    return vacanciesCopy;
  }
}

export function getFilteredOptions(options, search) {
  return [...options.filter((item) => (search ? item.title.toLowerCase().includes(search) : true))];
}

export const FilterTabTypes = {
  filters: 'filters',
  industry: 'industry',
  specialistLevel: 'specialistLevel',
};

export function getSelectedFiltersAmount(filterValue) {
  return (
    filterValue[filterOptionsItems.industry].length +
    filterValue[filterOptionsItems.specialistLevel].length
  );
}
