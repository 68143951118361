// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { fetchProfile, removeFeedbackFromQueue } from '@store/profile/profile';

import { AppDispatch } from '@store/index';

// TODO: Типизировать

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    convenience: 0,
    speed: 0,
    recommend: 0,
    comment: '',
    serviceName: '',
    relevance: 0,
    isRateService: false,
    isRateLate: false,
  },
  reducers: {
    setConvenience: (state, { payload }) => {
      state.convenience = payload;
    },
    setSpeed: (state, { payload }) => {
      state.speed = payload;
    },
    setRecommend: (state, { payload }) => {
      state.recommend = payload;
    },
    setComment: (state, { payload }) => {
      state.comment = payload;
    },
    setServiceName: (state, { payload }) => {
      state.serviceName = payload;
    },
    setRelevance: (state, { payload }) => {
      state.relevance = payload;
    },
    setIsRateService: (state, { payload }) => {
      state.isRateService = payload;
    },
    setIsRateLate: (state, { payload }) => {
      state.isRateLate = payload;
    },
    setDefaultRating: (state) => {
      state.convenience = 0;
      state.speed = 0;
      state.recommend = 0;
      state.comment = '';
    },
    setDefaultRatingService: (state) => {
      state.serviceName = '';
      state.relevance = 0;
      state.isRateService = false;
    },
  },
});

export const sendFeedback = (data) => async (dispatch: AppDispatch) => {
  const response = await axios.post(`/feedback`, data);

  if (response.data.success) {
    dispatch(feedbackSlice.actions.setDefaultRating());
    dispatch(fetchProfile());
    if (data.serviceName === 'system') {
      dispatch(removeFeedbackFromQueue('system-initiatives'));
    } else if (data.serviceName === 'hrsm') {
      dispatch(removeFeedbackFromQueue('hrsm'));
    } else if (data.serviceName === 'club') {
      localStorage.setItem('clubIsRated', 'true');
    }
  }
};

export const sendEmail = (data) => async () => {
  await axios.post('/feedback/welcome', data);
};

export default feedbackSlice;
