// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';
import axiosLib from 'axios';

import { getIdsCompareEditedArrays } from '@utils/compareEditedArray';
import { filterErrorsByField } from '@utils/validate';
import { removeEmptyFieldValues } from '@utils/removeEmptyFieldValues';

import commonSlice from '@store/common/common';

import { EDUCATION_FIELD_ERRORS } from './constants';

// TODO: типизировать

import { AppDispatch, RootState } from '@store/index';
import { ProfileEducationsState, EducationError } from './types';

export const profileEducationsSlice = createSlice({
  name: 'profileEducations',
  initialState: {
    initialProfileEducations: [],
    profileEducations: [],
    isEducationEdit: false,
    educationErrors: [],
  } as ProfileEducationsState,
  reducers: {
    setProfileEducations: (state, { payload }) => {
      state.profileEducations = payload;
    },
    setInitialProfileEducations: (state, { payload }) => {
      state.initialProfileEducations = payload;
    },
    setIsEducationEdit: (state, { payload }) => {
      state.isEducationEdit = payload;
    },
    setErrors: (state, { payload }: { payload: EducationError }) => {
      const findingError = state.educationErrors.find((error) => error.id == payload.id);

      state.educationErrors = findingError
        ? state.educationErrors.map((error) => {
            if (error.id === payload.id) {
              return {
                ...error,
                country: payload.country === '' ? '' : payload.country || error.country,
                city: payload.city === '' ? '' : payload.city || error.city,
                university: payload.university === '' ? '' : payload.university || error.university,
                position: payload.position === '' ? '' : payload.position || error.position,
                year: payload.year === '' ? '' : payload.year || error.year,
                chair: payload.chair === '' ? '' : payload.chair || error.chair,
                faculty: payload.faculty === '' ? '' : payload.faculty || error.faculty,
              };
            }
            return error;
          })
        : [...state.educationErrors, payload];
    },
    resetEducation: (state) => {
      state.educationError = [];
      state.isEducationEdit = false;
    },
  },
});

export const saveEducations =
  (userId: number, educations) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const newEducations = educations.filter((education) => !education.id);

    if (newEducations.length > 0) {
      dispatch(saveProfileEducations(userId, removeEmptyFieldValues(newEducations)));
    }

    const updatedEducations = educations.filter((education) => education.id);
    const filteredProfileEducations = getState().profileEducations.profileEducations.filter(
      (education) => education.id,
    );

    if (
      getIdsCompareEditedArrays(
        removeEmptyFieldValues(updatedEducations),
        removeEmptyFieldValues(filteredProfileEducations),
      ).length > 0
    ) {
      const educationsForUpdate = updatedEducations.filter((obj) =>
        getIdsCompareEditedArrays(updatedEducations, filteredProfileEducations).includes(obj.id),
      );

      dispatch(updateProfileEducations(userId, removeEmptyFieldValues(educationsForUpdate)));
    } else {
      dispatch(profileEducationsSlice.actions.setIsEducationEdit(false));
    }

    const deletedEducations = getState().profileEducations.initialProfileEducations.filter(
      (initialEducation) =>
        !educations.some((currentEducation) => currentEducation.id === initialEducation.id),
    );

    if (deletedEducations.length > 0) {
      dispatch(deleteProfileEducations(userId, deletedEducations));
    }
  };

export const fetchProfileEducations = (userId: number) => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.setLoading(true));

  try {
    const response = await axios.get(`/profile/${userId}/educations`);

    if (response.data) {
      dispatch(profileEducationsSlice.actions.setProfileEducations(response.data));
      dispatch(profileEducationsSlice.actions.setInitialProfileEducations(response.data));
    }
    dispatch(commonSlice.actions.setLoading(false));
  } catch (e) {
    // TODO handle error
    dispatch(commonSlice.actions.setLoading(false));
  }
};

export const saveProfileEducations =
  (userId: number, educationsData) => async (dispatch: AppDispatch) => {
    dispatch(commonSlice.actions.setLoading(true));
    try {
      await axiosLib.all(
        educationsData.map((education) => axios.post(`/profile/${userId}/educations`, education)),
      );
      await dispatch(fetchProfileEducations(userId));
      dispatch(commonSlice.actions.setLoading(false));
      await dispatch(profileEducationsSlice.actions.setIsEducationEdit(false));
    } catch (e) {
      if (e?.response.data.statusCode === 400) {
        dispatch(handleValidateErrors(e));
      }

      dispatch(commonSlice.actions.setLoading(false));
    } finally {
      dispatch(commonSlice.actions.setLoading(false));
    }
  };

export const updateProfileEducations =
  (userId: number, educationsData) => async (dispatch: AppDispatch) => {
    dispatch(commonSlice.actions.setLoading(true));

    try {
      await axiosLib.all(
        educationsData.map((education) => {
          // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
          const { id, profileId, ...rest } = education;
          return axios.patch(`/profile/${userId}/educations/${id}`, rest);
        }),
      );
      await dispatch(fetchProfileEducations(userId));
      dispatch(commonSlice.actions.setLoading(false));
      await dispatch(profileEducationsSlice.actions.setIsEducationEdit(false));
    } catch (e) {
      if (e?.response.data.statusCode === 400) {
        dispatch(handleValidateErrors(e));
      }

      dispatch(commonSlice.actions.setLoading(false));
    }
  };

export const deleteProfileEducations =
  (userId: number, educationsData) => async (dispatch: AppDispatch) => {
    dispatch(commonSlice.actions.setLoading(true));
    try {
      await axiosLib.all(
        educationsData.map((education) =>
          axios.delete(`/profile/${userId}/educations/${education.id}`),
        ),
      );
      await dispatch(fetchProfileEducations(userId));
      dispatch(commonSlice.actions.setLoading(false));
      await dispatch(profileEducationsSlice.actions.setIsEducationEdit(false));
    } catch (e) {
      // TODO handle error
      dispatch(commonSlice.actions.setLoading(false));
    }
  };

const handleValidateErrors =
  (requestError) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const errors = requestError?.response?.data?.message?.map((error) => error.property);

    if (errors.length > 0) {
      const filteredErrors = filterErrorsByField(errors, EDUCATION_FIELD_ERRORS);

      dispatch(
        profileEducationsSlice.actions.setErrors({
          ...getState().profileEducations.educationError,
          ...filteredErrors,
        }),
      );

      dispatch(profileEducationsSlice.actions.setIsEducationEdit(true));
    }
  };

export default profileEducationsSlice;
