// @ts-nocheck
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { Button } from '@components/core-ui/Button/Button';
import { Modal } from '@components/core-ui/Modal/Modal';

import styles from './ErrorPage.module.scss';

export const ErrorPage = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/lead/account');
    refreshPage();
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Modal
      containerClassName={styles.container}
      contentClassName={styles.body}
      closeIconClassName={styles.close}
      backdropClassName={styles.backdrop}
      onClose={handleClose}
    >
      <div className={styles.content}>
        <span className={styles.title}> Произошла ошибка</span>
        <div className={styles.frame}>
          <div className={styles.textAndImg}>
            <div className={styles.textAndImg_text}>
              <span>Мы уже работаем над этим.</span>

              {isMobile ? (
                <span>
                  Перезагрузите страницу, возможно, <br /> это поможет.
                </span>
              ) : (
                <span>Перезагрузите страницу, возможно, это поможет.</span>
              )}
            </div>
            <img src='/images/gif/squirrel.gif' alt='' />
          </div>

          <Button className={styles.button} onClick={refreshPage}>
            Перезагрузить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
