import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import commonSlice from '@store/common/common';

import { AppDispatch } from '@store//index';

const LoaderToggler = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(commonSlice.actions.setGlobalLoading(true));
    return () => {
      setTimeout(() => {
        dispatch(commonSlice.actions.setGlobalLoading(false));
      }, 500);
    };
  }, [dispatch]);

  return <div></div>;
};

export default LoaderToggler;