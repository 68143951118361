import { CommunityStatus, CommunityType, ProfileCommunity } from './types';

export const transformCommunitiesForClub = (communities: ProfileCommunity[]) => {
  const filteredCommunitiesByType = communities.filter(
    (community) => community.communityType !== CommunityType.CUSTOM,
  );

  const approvedCommunities: ProfileCommunity[] = filterCommunitiesByStatus(
    filteredCommunitiesByType,
    CommunityStatus.APPROVED,
  );

  const approvedSortedByTime = sortCommunityByUpdate(approvedCommunities);

  const moderatedCommunities: ProfileCommunity[] = filterCommunitiesByStatus(
    filteredCommunitiesByType,
    CommunityStatus.ON_MODERATION,
  );

  const moderatedSortedByTime = sortCommunityByUpdate(moderatedCommunities);

  return sortCommunityByStatus([...approvedSortedByTime, ...moderatedSortedByTime]);
};

export const filterCommunitiesByStatus = (
  communities: ProfileCommunity[],
  status: CommunityStatus,
) => {
  return communities.filter((community) => community.status === status);
};

const sortCommunityByStatus = (communities: ProfileCommunity[]) => {
  return communities.sort((prev, next) => {
    if (prev.status > next.status) {
      return 1;
    }

    if (prev.status < next.status) {
      return -1;
    }

    return 0;
  });
};

const sortCommunityByUpdate = (communities: ProfileCommunity[]) => {
  return communities.sort((prev, next) => {
    const updatedTimePrev = new Date(prev.updatedAt).getTime();
    const updatedTimeNext = new Date(next.updatedAt).getTime();

    if (updatedTimePrev > updatedTimeNext) {
      return 1;
    }

    if (updatedTimePrev < updatedTimeNext) {
      return -1;
    }

    return 0;
  });
};
