// @ts-nocheck

// TODO: типизировать

// Метод для сравнение двух массивов объектов, возвращающий массив id объектов, которые были изменены
export const getIdsCompareEditedArrays = (array1, array2) => {
  return array1
    .filter((obj, index) => {
      return (
        Object.keys(obj).length !== Object.keys(array2[index]).length ||
        !Object.keys(obj).every((key) => {
          if (key === 'users') {
            return isArraysEqual(obj[key], array2[index][key]);
          }
          return obj[key] === array2[index][key];
        })
      );
    })
    .map((obj) => obj.id);
};

// Сравнение примитивных массивов
const isArraysEqual = (arr1, arr2) => arr1.toString() === arr2.toString();
