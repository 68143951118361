import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';
import axiosLib, { AxiosError } from 'axios';

import { transformCommunitiesForClub } from './utils';

import communityPageSlice from '@store/communityPage/communityPage';

import { AppDispatch, RootState } from '@store/index';
import {
  BusinessCommunity,
  BusinessCommunityState,
  ChangeCommunity,
  CommunityErrors,
  CommunityFromRequest,
  CustomCommunity,
  FetchAllCommunitiesParams,
  ProfileCommunity,
} from './types';

const COMMUNITY_STATUSES = {
  APPROVED: 'approved',
  ON_MODERATION: 'onModeration',
  REJECTED: 'rejected',
};

export const businessCommunitySlice = createSlice({
  name: 'businessCommunity',
  initialState: {
    isLoading: false,
    allCommunities: null,
    approvedCommunities: [],
    profileCommunities: [],
    businessCommunities: null,
    clubCommunities: [],
    templateCommunities: null,
    removedCommunityIds: [],
    communityErrors: {},
    filteredCommunities: [],
  } as BusinessCommunityState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setAllCommunities: (state, { payload }) => {
      state.allCommunities = payload;
    },
    setBusinessCommunities: (state, { payload }: { payload: BusinessCommunity[] }) => {
      state.businessCommunities = payload;
    },
    setProfileCommunities: (
      state,
      { payload }: { payload: ProfileCommunity[] | ChangeCommunity[] },
    ) => {
      state.profileCommunities = payload;
    },
    setClubCommunities: (state, { payload }: { payload: ProfileCommunity[] }) => {
      state.clubCommunities = payload;
    },
    setApprovedCommunities: (state, { payload }) => {
      state.approvedCommunities = payload;
    },
    setTemplateCommunities: (state, { payload }) => {
      state.templateCommunities = payload;
    },
    setRemovedCommunityIds: (state, { payload }: { payload: number }) => {
      state.removedCommunityIds = [...state.removedCommunityIds, payload];
    },
    resetRemovedCommunityIds: (state) => {
      state.removedCommunityIds = [];
    },
    setCommunityErrors: (state, { payload }: { payload: Partial<CommunityErrors> }) => {
      state.communityErrors = payload;
    },
    setFilteredCommunities: (state, { payload }: { payload: ProfileCommunity[] }) => {
      state.filteredCommunities = payload;
    },
  },
});

export const fetchAllCommunities =
  (params: FetchAllCommunitiesParams) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get('/communities', {
        params,
      });

      if (response.data) {
        dispatch(businessCommunitySlice.actions.setAllCommunities(response.data.data));
      }
    } catch (e) {
      // TODO handle error
    }
  };

export const fetchProfileCommunities =
  (userId?: number, isOtherProfile?: boolean) => async (dispatch: AppDispatch) => {
    dispatch(businessCommunitySlice.actions.setIsLoading(true));

    try {
      const response = await axios.get<CommunityFromRequest[]>('/profile/communities/requests', {
        params: { userId },
      });

      if (response.data) {
        const communityIds: number[] = response.data.map((item) => item.communityId);

        const communityInfo = await axiosLib
          .all(communityIds.map((communityId) => axios.get(`/communities/${communityId}`)))
          .then((data) => data.map((item) => item.data));

        const mergedArray: ProfileCommunity[] = response.data.map((item) => {
          const matchingItem = communityInfo.find((element) => element.id === item.communityId);

          return { ...item, ...matchingItem };
        });

        const filteredCommunities = transformCommunitiesForClub(mergedArray);
        dispatch(businessCommunitySlice.actions.setClubCommunities(filteredCommunities));

        const approved = filteredCommunities.filter(
          (item) => item.status === COMMUNITY_STATUSES.APPROVED,
        );

        dispatch(businessCommunitySlice.actions.setProfileCommunities(mergedArray));
        dispatch(businessCommunitySlice.actions.setApprovedCommunities(approved));
        dispatch(businessCommunitySlice.actions.setTemplateCommunities(mergedArray));

        if (approved.length && !isOtherProfile) {
          const approvedWithoutCustom = approved.filter(
            (community) => community.communityType !== 'custom',
          );

          dispatch(communityPageSlice.actions.setMenuCommunities(approvedWithoutCustom));
        }
      }
    } catch (e) {
      console.log('Не удалось запросить сообщества пользователя');
    } finally {
      dispatch(businessCommunitySlice.actions.setIsLoading(false));
    }
  };

export const requestCommunity =
  (communityIds: number[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      await axiosLib.all(
        communityIds.map((communityId) =>
          axios.post(`/profile/communities/requests`, { communityId }),
        ),
      );

      await dispatch(fetchProfileCommunities(Number(getState().auth.user?.id)));
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.status === 400) {
        dispatch(
          businessCommunitySlice.actions.setCommunityErrors({
            default: 'Ошибка сохранения сообщества',
          }),
        );
      }
    }
  };

export const fetchBusinessCommunities = () => async (dispatch: AppDispatch) => {
  const response = await axios.get<BusinessCommunity[]>(
    '/profile/communities/filter/?limit=20&text=',
  );

  if (response.data) {
    dispatch(businessCommunitySlice.actions.setBusinessCommunities(response.data));
  }
};

export const removeProfileCommunity =
  (communityIds: number[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    await axiosLib.all(
      communityIds.map((communityId) =>
        axios.delete(`/profile/communities/requests`, { data: { communityId } }),
      ),
    );

    await dispatch(fetchProfileCommunities(Number(getState().auth.user?.id)));
  };

export const requestNewCommunity =
  (newCommunities: CustomCommunity[]) => async (dispatch: AppDispatch) => {
    try {
      const responses = await axiosLib.all(
        newCommunities.map((newCommunity) =>
          axios.post(`/communities`, { title: newCommunity.title, url: newCommunity.url }),
        ),
      );

      if (responses) {
        const ids: number[] = responses.map((response) => response.data.id);
        await dispatch(requestCommunity(ids));
      }
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.status === 400) {
        dispatch(
          businessCommunitySlice.actions.setCommunityErrors({
            emptyCustomName: 'Название сообщества не может быть пустым',
          }),
        );
      }
    }
  };

export default businessCommunitySlice;
