import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import commonSlice from '@store/common/common';

import { Logo } from '@components/common/Logo';

import styles from './GlobalLoader.module.scss';

import { AppDispatch } from '@store/index';

const GlobalLoader = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(commonSlice.actions.setGlobalLoading(false));
    }, 500);
  }, [dispatch]);

  return (
    <div className={styles.globalLoader}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.progress}>
        <div className={styles.line}></div>
      </div>
    </div>
  );
};

export default GlobalLoader;
