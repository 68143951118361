import CircularProgress from '@mui/material/CircularProgress';
import styles from './GradientBlockProgress.module.scss';

type GradientBlockProgressProps = {
  size?: number;
  thickness?: number;
  show?: boolean;
};

export const GradientBlockProgress = ({
  size = 50,
  thickness = 5,
  show = true,
}: GradientBlockProgressProps) => {
  if (!show) return null;

  return (
    <div className={styles.progress}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id='my_gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%' stopColor='#e01cd5' />
            <stop offset='100%' stopColor='#1CB5E0' />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
        size={size}
        thickness={thickness}
      />
    </div>
  );
};
