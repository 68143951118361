import { CommunityType } from '@store/businessCommunity/types';
import { CommunityResponse } from './types';

export const initialCommunity: CommunityResponse = {
  id: 0,
  title: '',
  url: '',
  isPublic: false,
  communityType: CommunityType.CONNECTED,
  page: {
    id: 0,
    avatar: '',
    slogan: '',
    bgColor: '',
    availableColors: [],
    telegram: '',
    about: '',
    communityId: 0,
  },
  mainCommunityId: 0,
};

export const MESSAGE_EVENTS = {
  VALID: 'valid',
  INVALID: 'invalid',
  HEIGHT: 'height',
};
