import { icons } from '@icons/icons';

import { Button } from '@components/core-ui/Button/Button';
import axios from '@utils/axios';

import styles from './FinishImpersonateButton.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';

export const FinishImpersonateButton = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  const finishImpersonate = async () => {
    await axios.post(`/v2/admin/users/${user?.id}/logout`);
    window.open(
      `/admin/users/filter#limit=20&page=1&sortBy=id&status=all&userId=${user?.id}`,
      '_top',
    );
  };

  return (
    <div className={styles.button}>
      <Button outline={true} size='small' icon={icons.logout} onClick={finishImpersonate}>
        Вернуться в панель администратора
      </Button>
    </div>
  );
};
