import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscription: null,
  },
  reducers: {
    setSubscription(state, action) {
      state.subscription = action.payload;
    },
  },
});

export const fetchSubscription = () => async (dispatch: AppDispatch) => {
  const response = await axios.get('/payments/subscription-summary/');
  dispatch(subscriptionSlice.actions.setSubscription(response.data));
};

export const paySubscription = (subscriptionId: number) => async (dispatch: AppDispatch) => {
  await axios.get(`/payments/pay-subscription?subscriptionId=${subscriptionId}`);
  dispatch(fetchSubscription());
};

export default subscriptionSlice;
