import { FC } from 'react';

import styles from './Icon.module.scss';

interface IconProps {
  name: string;
  size?: IconSize;
  alt?: string;
}

enum IconSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  big = 'big',
}

export const Icon: FC<IconProps> = ({ name }) => {
  return <div className={styles.icon} dangerouslySetInnerHTML={{ __html: name }} />;
};
